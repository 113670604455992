// React
import { createStyles, makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";

// Material - ui
import ListItem from "@material-ui/core/ListItem";

// Component
import CardCategory from "../CardCategory/CardCategory";
import CardSubject from "../CardSubject/CardSubject";

// Models
import { ThemeCardFragment } from "../../graphql";
import { getIsSuperAdmin } from "../../services/user.service";

/**
 * Styles
 */
const useStyles = makeStyles(() =>
  createStyles({
    cardCategory: {
      "&:hover": {
        backgroundColor: "transparent", // Enlève l'effet gris au survol
      },
      width: "100%",

      paddingBottom: "0px",
    },
    cardSubCategory: {
      width: "100%",
      zIndex: 10,
    },

    cardSubject: {
      width: "100%",
      paddingTop: "0px",
      paddingBottom: "0px",
    },
  })
);

/**
 * CardCategory props
 */
interface BaseSkillsItemProps {
  category: ThemeCardFragment;
  domainSlug: string;
  locked?: boolean;
  onLockedClick?: () => void;
}

/**
 * Card category
 */
const BaseSkillsItem = ({
  category,
  domainSlug,
  locked,
  onLockedClick,
}: BaseSkillsItemProps) => {
  /** state variable open */
  const [open, setOpen] = useState(false);
  /** Classes */
  const classes = useStyles();

  const isSuperAdmin = getIsSuperAdmin();
  /**
   * Has children
   * @param category
   */
  // function hasChildren(element: Category): boolean {
  //   return element && element.childrensData
  //     ? element.childrensData.length > 0
  //     : false;
  // }

  // useEffect(() => {
  //   setOpen(categoryListOpened.some((cat) => cat._id === category._id));
  // }, [categoryListOpened, category]);

  return (
    <React.Fragment>
      {category.children.length > 0 ? (
        <ListItem
          className={classes.cardCategory}
          button={true}
          disableGutters={true}
          disableRipple
          onClick={() => setOpen((open) => !open)}
        >
          <CardCategory
            key={category._id}
            category={category}
            domainSlug={domainSlug}
            isExpandLess={open}
            locked={locked}
            onLockedClick={onLockedClick}
          />
        </ListItem>
      ) : isSuperAdmin ||
        category.recursiveCounts.lessons + category.recursiveCounts.quizzes >
          0 ? (
        <ListItem disableGutters={true} className={classes.cardSubject}>
          <CardSubject
            key={category._id}
            category={category}
            domainSlug={domainSlug}
            locked={locked}
            onLockedClick={onLockedClick}
          />
        </ListItem>
      ) : null}

      {/* {category.children.length > 0
        ? category.childrensMapped.map((subcategory: Category) => (
            <Collapse
              key={subcategory._id}
              className={classes.cardSubCategoryCollapse}
              in={open}
              timeout="auto"
              unmountOnExit={true}
            >
               <List disablePadding={true} component="div">
                <BaseSkillsItem
                  key={subcategory._id}
                  category={subcategory}
                  isLevelCategory={false}
                  categoryListOpened={categoryListOpened}
                  domain={domain}
                  locked={locked}
                  onLockedClick={onLockedClick}
                />
              </List> 
            </Collapse>
          ))
        : null} */}
    </React.Fragment>
  );
};

export default React.memo(BaseSkillsItem);
