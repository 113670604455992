import { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { RouteComponentProps } from "react-router";
import { Box, Grid, Theme, createStyles, makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { StoreState } from "../../store";
import CardActivity2 from "../../features/CardActivity/CardActivity2";
import { FakeCardActivity } from "../../features/CardActivity/CardActivity";
import { User } from "../../models/user.model";
import { getDomainById } from "../../helpers/domain.helper";
import {
  GutenbergProduct,
  LessonNewsDataFragment,
  OrderDirection,
  QuizNewsDataFragment,
  useLessonsNewsLazyQuery,
  useQuizNewsLazyQuery,
} from "../../graphql";

// Assets
//import bandeau from "../../assets/Année_2024@2x.png";

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      [theme.breakpoints.up("lg")]: {
        paddingLeft: "10%",
        paddingRight: "10%",
      },
      [theme.breakpoints.down("md")]: {
        paddingLeft: 40,
        paddingRight: 40,
      },
      [theme.breakpoints.down("sm")]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
      "@supports (-webkit-touch-callout: none)": {
        marginBottom: 149,
      },
    },
    bandeau: {
      width: "100%",
      marginBottom: 12,
    },
  })
);

/**
 * Home component
 */
const Home = ({ history }: RouteComponentProps) => {
  const classes = useStyles();

  const [
    getLessonsNews,
    {
      data: lessonListData,
      loading: loadingLessonList,
      error: errorLessonList,
    },
  ] = useLessonsNewsLazyQuery({
    errorPolicy: "ignore",
    fetchPolicy: "cache-and-network",
  });

  const [
    getQuizzesNews,
    { data: quizListdata, loading: loadingQuizList, error: errorQuizList },
  ] = useQuizNewsLazyQuery({
    errorPolicy: "ignore",
    fetchPolicy: "cache-and-network",
  });

  /** Processing time state */
  const user: User | null = useSelector((state: StoreState) => state.user);

  const [activityList, setActivityList] = useState<
    (LessonNewsDataFragment | QuizNewsDataFragment)[]
  >([]);

  const mergedActivities = useMemo(() => {
    const lessons = lessonListData?.lessons_2406?.data || [];
    const quizzes = quizListdata?.quizzes_2406?.data || [];
    const combined = [...lessons, ...quizzes];

    const uniqueActivities = new Map<
      string,
      LessonNewsDataFragment | QuizNewsDataFragment
    >();
    combined.forEach((item) => uniqueActivities.set(item._id, item));

    return Array.from(uniqueActivities.values()).sort(
      (a, b) =>
        new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
    );
  }, [lessonListData, quizListdata]);

  /**
   * Use effect get activity list
   */
  useEffect(() => {
    getLessonsNews({
      variables: {
        search: {
          tags: ["A_LA_UNE"],
          products: [GutenbergProduct.RfFormation],
        },
        pagination: {
          order: OrderDirection.Desc,
          page: 1,
          per_page: 50,
        },
      },
    });

    getQuizzesNews({
      variables: {
        search: {
          tags: ["A_LA_UNE"],
          products: [GutenbergProduct.RfFormation],
        },
        pagination: {
          order: OrderDirection.Desc,
          page: 1,
          per_page: 50,
        },
      },
    });
  }, [getLessonsNews, getQuizzesNews]);

  useEffect(() => {
    setActivityList(mergedActivities);
  }, [mergedActivities]);

  useEffect(() => {
    if (
      user?.isBlended &&
      !user.subscription.fullDomainsAccessEl.length &&
      user.subscription.domainsAccessEl.length
    ) {
      const firstDomain = getDomainById(user.subscription.domainsAccessEl[0]);
      history.push("categorie/" + firstDomain.slug + "/competences");
    }
    console.log('DOMAIN ACCES USER', user.subscription.domainsAccessEl)
  }, [history, user]);

  return (
    <div>
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <title>À la une</title>
      </Helmet>
      <Grid
        container={true}
        direction="column"
        spacing={2}
        className={classes.container}
      >
        {/*  <img
          src={bandeau}
          alt="Nous vous souhaitons bonne fete"
          className={classes.bandeau}
        /> */}
      </Grid>

      {loadingLessonList || loadingQuizList ? (
        <Box marginTop={3}>
          <FakeCardActivity />
        </Box>
      ) : !loadingLessonList &&
        !loadingQuizList &&
        errorLessonList &&
        errorQuizList ? (
        <p>
          Cette ressource est inacessible pour le moment, veuillez reassayer
          dans un court instant
        </p>
      ) : (
        <>
          {!loadingLessonList &&
        !loadingQuizList && activityList &&
            activityList.filter((activity) =>
              user.subscription.domainsAccessEl.some((domain) =>
                activity.parentCategories[0].fullPath.includes(domain.toString())
              )).map((activity) => {
              return (
                <Box id={activity._id} key={activity._id} marginTop={1}>
                  <CardActivity2 activity={activity} />
                </Box>
              );
            })}
        </>
      )}
    </div>
  );
};

export default Home;
