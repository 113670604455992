// React
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";

// Material - ui
import { Grid, Typography } from "@material-ui/core";

// Helpers
import { isPlurial } from "../../helpers/card.helper";

// Colors
import { DomainInfo } from "../../models/domain.model";

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    timeList: {
      flexDirection: "row",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    timeLabel: {
      fontSize: "12px",
      lineHeight: 1.5,
      letterSpacing: "0.87px",
      color: "#65747b",
    },
    timeValue: {
      display: "inline-block",
      fontSize: "12px",
      fontWeight: 600,
      letterSpacing: "-0.3px",
      color: "#263c46",
    },
    activityValue: {
      fontSize: "12px",
      letterSpacing: "0.86px",
      color: "#65747b",
    },
    vrBorderGrid: {
      padding: "0 10px",
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    vrBorder: {
      borderLeft: "solid 1px rgba(0, 0, 0, 0.2)",
      width: "1px",
      height: "13px",
    },
  })
);

/**
 * TimeIndicator props
 */
interface TimeIndicatorProps {
  isCategory: boolean;
  domain?: DomainInfo;
  color?: string;
  confirmedTime: string;
  estimedTime?: string;
  nbActivities?: number;
  isQuiz?: boolean;
  questionCount?: number;
  validatedQuestionCount?: number;
}

/**
 * Time indicator
 */
const TimeIndicator = ({
  isCategory,
  domain,
  color,
  confirmedTime,
  estimedTime,
  nbActivities,
  isQuiz,
  questionCount,
  validatedQuestionCount,
}: TimeIndicatorProps) => {
  /** Classes */
  const classes = useStyles();

  return (
    <Grid
      className={classes.timeList}
      container={true}
      item={true}
      justifyContent="flex-start"
    >
      {isCategory && nbActivities !== undefined ? (
        <Grid item={true}>
          <Typography
            component="span"
            className={classes.activityValue}
            gutterBottom={true}
          >
            {nbActivities} {isPlurial(nbActivities)}
          </Typography>
        </Grid>
      ) : (
        <React.Fragment>
          <Grid item={true}>
            <Typography
              component="span"
              className={classes.timeLabel}
              color="textSecondary"
              gutterBottom={true}
              noWrap={true}
            >
              Temps de l’activité&nbsp;
            </Typography>
            <Typography
              component="span"
              className={classes.timeValue}
              gutterBottom={true}
            >
              {estimedTime}
            </Typography>
          </Grid>
          {isQuiz ? (
            <React.Fragment>
              <Grid item={true} className={classes.vrBorderGrid}>
                <div className={classes.vrBorder} />
              </Grid>
              <Grid item={true}>
                <Typography
                  component="span"
                  className={classes.timeLabel}
                  color="textSecondary"
                  gutterBottom={true}
                  noWrap={true}
                >
                  Questions réalisées&nbsp;
                </Typography>
                <Typography
                  component="span"
                  className={classes.timeValue}
                  gutterBottom={true}
                >
                  {validatedQuestionCount}/{questionCount}
                </Typography>
              </Grid>
            </React.Fragment>
          ) : null}
        </React.Fragment>
      )}
      {//! TODO faire l'appel pour recuperer les temps realisées aggregé des activités et de faire le système de De tag  NEW OU UPDATE ( basé sur le tag )
        true ? (
          <>
            <Grid item={true} className={classes.vrBorderGrid}>
              <div className={classes.vrBorder} />
            </Grid>
            <Grid item={true}>
              <Typography
                component="span"
                className={classes.timeLabel}
                color="textSecondary"
                gutterBottom={true}
                noWrap={true}
              >
                Temps de formation réalisé&nbsp;
              </Typography>
              <Typography
                component="span"
                className={classes.timeValue}
                gutterBottom={true}
                style={{ color: color ?? "fffff" }}
              >
                {confirmedTime}
              </Typography>
            </Grid>
          </>
        ) : null
      }
    </Grid>
  );
};

export default React.memo(TimeIndicator);
