import { DomainInfo } from "./domain.model";
import { LessonDataFragment, QuizDataFragment } from "../graphql";
import { ParentMapped } from "./category.model";

/**
 * Activity mapped members
 */
export interface ActivityMappedMembers {
  state: ActivityState | null;
  domain: DomainInfo;
  confirmedTime: number;
  progress: number;
  isStarted: boolean;
  isDone: boolean;
  validatedQuestionCount: number;
  parentMapped?: ParentMapped;
  type?: string;
  nature?: string;
}

export type Quiz = QuizDataFragment & ActivityMappedMembers;
export type Lesson = LessonDataFragment & ActivityMappedMembers;

export type Activity = Quiz | Lesson;

/**
 * Activity state enum
 */
export enum ActivityState {
  UPDATE = "Mise à jour",
  NEW = "Nouveauté",
}

/**
 * QuizStarterListItem interface
 */
export interface StarterQuizListItem {
  _id: string;
  isStarted: boolean;
}

/**
 * QuizStarterList interface
 */
export interface StarterQuizList {
  [key: number]: StarterQuizListItem;
}

/**
 * Activity natures
 */
export const NATURES = [
  "A_RETENIR",
  "ACTU",
  "CAS_PRATIQUE",
  "DECRYPTAGE",
  "ESSENTIEL",
  "FLASH",
  "MEMO",
  "QCM",
  "QUIZ",
  "SAVOIR",
  "TABLEAU",
  "VIDEO",
  "L_ILLUSTRE",
  "SYNTHÈSE",
];

/**
 * Activity natures
 */
export const TYPES = ["PRATIQUE_PRO", "ACTU_METIER", "PRATIQUE_SECTORIELLE"];
