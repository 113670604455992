import React, { useEffect, useState } from "react";

// Lib
import { useNavigatorOnline } from "@oieduardorabelo/use-navigator-online";
import { useSnackbar } from "notistack";
import { useAuthentication } from "@dsk-lib/user";

// Components
import ServiceUnvailable from "../../views/ServiceUnvailable/ServiceUnvailable";

// Hooks
import { KeycloakInstance } from "keycloak-js";

/**Fetch Intercept */
import fetchIntercept from "fetch-intercept";

// store
import { store } from "../../store/createStore";
import { useSelector } from "react-redux";
import { StoreState } from "../../store";

interface IWithErrorHandler {
  setUserUnauthorized: React.Dispatch<React.SetStateAction<boolean>>;
  children: JSX.Element[];
}

const WithErrorHandler = (props: IWithErrorHandler) => {
  /** handle error */
  const [error, setError] = useState<boolean>(false);

  /** network state */
  const { isOffline } = useNavigatorOnline();
  /** use snackbar */
  const { enqueueSnackbar } = useSnackbar();

  const { isError } = useSelector((state: StoreState) => state.errorHandler);

  /** Keycloak */
  const { keycloak } = useAuthentication();
  const kc = keycloak as KeycloakInstance | null;

  useEffect(() => {
    if (isError) {
      setError(true);
    }
  }, [isError]);

  /**
   * This useEffect check if after 5000 ms the user authenticated
   */
  useEffect(() => {
    setTimeout(() => {
      if (kc?.authenticated === false) {
        setError(true);
      }
    }, 5000);
  }, [keycloak]);

  /** Use effect */
  useEffect(() => {
    if (isOffline) {
      enqueueSnackbar("Attention, vous avez perdu la connexion à Internet.", {
        variant: "error",
      });
    }
  }, [isOffline]);

  /**
   * Reload home page
   */
  const reloadHome = (): void => {
    window.location.href = `http://${window.location.host}`;
  };

  /**
   * Intercept fetch request
   */
  useEffect(() => {
    fetchIntercept.register({
      request: function (url, config) {
        return [url, config];
      },

      requestError: function (error) {
        return Promise.reject(error);
      },

      response: function (response) {
        if (response.status === 500) {
          enqueueSnackbar(
            "Une erreur est survenue durant la récupération des données. Certains éléments peuvent ne pas être affichés.",
            {
              variant: "error",
            }
          );
        } else if (response.status === 503) {
          setError(true);
          setTimeout(reloadHome, 5000);
        } else if (response.status === 403) {
          props.setUserUnauthorized(true);
        }
        return response;
      },

      responseError: function (error) {
        return Promise.reject(error);
      },
    });
  }, []);

  return (
    <React.Fragment>
      {error ? (
        <ServiceUnvailable statusCode={store.getState().errorHandler.code} />
      ) : (
        props.children
      )}
    </React.Fragment>
  );
};

export default React.memo(WithErrorHandler);
