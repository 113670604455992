import React, { useEffect, useMemo } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  CircularProgress,
  Hidden,
  LinearProgress,
  CardActions,
  Paper,
  IconButton,
  Snackbar,
  Chip,
} from "@material-ui/core";
import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
} from "@material-ui/core/styles";
import { getIsSuperAdmin } from "../../services/user.service";
import { yellow, grey } from "@material-ui/core/colors";
import clockIcon from "../../assets/ic-estime.svg";
import GetAppIcon from "@material-ui/icons/GetApp";
import CloseIcon from "@material-ui/icons/Close";
import { getActivityWord2 } from "../../services/activity.service";
import {
  formatStringToDate,
  secondsToMinutes,
} from "../../helpers/date-fomatter.helper";
import clsx from "clsx";
import "../../styles/progressbar.scss";

import ColorCircularProgress from "../../features/ColorCircularProgress/ColorCircularProgress";
import { cleanAccentLabel } from "../../helpers/card.helper";
import {
  BookmarkStatus,
  LessonNewsDataFragment,
  QuizNewsDataFragment,
  Status,
  useLessonWordLazyQuery,
  useQuizWordLazyQuery,
  useStarterQuizProgressLazyQuery,
  useTimeActivityLazyQuery,
} from "../../graphql";
import { useAuthentication } from "@dsk-lib/user";
import { DEFAULT_DOMAIN, DOMAINS } from "../../models/domain.model";
import TimeIndicator2 from "../TimeIndicator/TimeIndicator2";
import { NATURES } from "../../models/Activity.model";

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    noEvent: {
      pointerEvents: "none",
    },
    cardContent: {
      paddingBottom: 0,
    },
    cardActions: {
      paddingTop: 0,
    },
    dot: {
      margin: 1,
      marginRight: 10,
      width: 8,
      height: 8,
      borderRadius: "50%",
      display: "inline-block",
    },
    dotUpdate: {
      backgroundColor: yellow[600],
    },
    dotNew: {
      backgroundColor: theme.palette.primary.main,
    },
    wrapper: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    wrapperContent: {
      margin: theme.spacing(1),
      position: "relative",
    },
    card: {
      padding: theme.spacing(0.5, 0.5),
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#f0f0f0",
      },
    },
    chipDomain: {
      color: "white",
      margin: theme.spacing(0.5),
    },
    chipNature: {
      backgroundColor: "#565554",
      color: "white",
      margin: theme.spacing(0.5),
      textTransform: "capitalize",
    },
    chipType: {
      backgroundColor: "white",
      color: "black",
      margin: theme.spacing(0.5),
      textTransform: "capitalize",
    },
    title: {
      fontSize: 16,
      justifyContent: "center",
    },
    titleTime: {
      fontWeight: 500,
    },
    cardFooter: {
      margin: theme.spacing(0.5),
    },
    clockIcon: {
      display: "flex",
      width: 24,
      height: 24,
    },
    stateType: {
      [theme.breakpoints.down("sm")]: {
        justifyContent: "flex-start",
      },
    },
    date: {
      fontSize: 14,
    },
    circularLoader: {
      color: grey[300],
      zIndex: 1,
    },
    circulartext: {
      position: "absolute",
      top: 14,
      width: 50,
      height: 20,
      textAlign: "center",
      display: "block",
      color: theme.palette.primary.main,
      zIndex: 2,
    },
    circular: {
      position: "absolute",
      color: theme.palette.primary.main,
      zIndex: 2,
    },
    circularBackground: {
      color: grey[300],
      zIndex: 1,
    },
    progressContainer: {
      position: "relative",
    },
    container: {
      minWidth: 320,
      maxWidth: 1000,
      margin: "0 auto",
    },
    linearProgressBar: {
      root: {
        height: 10,
        backgroundColor: theme.palette.primary.main,
      },
      bar: {
        borderRadius: 20,
        backgroundColor: theme.palette.primary.main,
      },
    },
    time: {
      color: theme.palette.primary.main,
      margin: theme.spacing(1),
    },
    cardDownload: {
      "&:hover": {
        backgroundColor: "#f0f0f0",
      },
      cursor: "pointer",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        borderTop: "1px dashed gray",
      },
      [theme.breakpoints.up("md")]: {
        borderLeft: "1px dashed gray",
      },
    },
    iconDownload: {
      [theme.breakpoints.down("sm")]: {
        margin: theme.spacing(1),
        justifyContent: "flex-end",
      },
      [theme.breakpoints.up("md")]: {
        justifyContent: "center",
      },
    },
    downloadText: {
      margin: theme.spacing(1),
      lineHeight: 20,
      display: "inline-block",
    },
    maxHeight: {
      height: "100%",
    },
    locked: {
      pointerEvents: "none",
      opacity: 0.5,
      cursor: "not-allowed",
    },
  })
);

/**
 * Border linear progress
 */
const BorderLinearProgress = withStyles({
  root: {
    width: 50,
    height: 8,
    borderRadius: 20,
    backgroundColor: "#dddfdf",
  },
  bar: {
    borderRadius: 20,
  },
})(LinearProgress);

/**
 * Card activity props
 */
interface CardActivity2Props {
  activity: LessonNewsDataFragment | QuizNewsDataFragment;
  locked?: boolean;
  onLockedClick?: () => void;
}

/**
 * Card activity
 */
const CardActivity2 = (props: RouteComponentProps & CardActivity2Props) => {
  /** Props */
  const { match, activity, history, locked, onLockedClick } = props;

  const domain =
    DOMAINS.find((domain) =>
      activity.parentCategories[0].fullPath.includes(domain.fullPath)
    ) ?? DEFAULT_DOMAIN;

  /** Keycloak */
  const { fetchWithCredentials, keycloak } = useAuthentication();

  /** Classes  */
  const classes = useStyles();
  /** isSuperAdmin */
  const [isSuperAdmin, setIsSuperAdmin] = React.useState<boolean>(false);
  /** loadingWordFile */
  const [isLoadingWordFile, setIsLoadingWordFile] = React.useState(false);
  /** errorWordFile */
  const [isErrorWordFile, setIsErrorWordFile] = React.useState(false);
  /** Dispatch activity selected */
  //const dispatch = useActivityDispatch();
  /** Use activity selected */
  // const { activitySelected } = useActivityState();
  /** Progress state (needed for animation) */

  /** Scroll Provider */
  //const dispatchScroll = useScrollDispatch();

  const [getQuizWord, { data: quizWordData }] = useQuizWordLazyQuery({
    errorPolicy: "all",
    fetchPolicy: "cache-and-network",
  });

  const [getLessonWord, { data: lessonWordData }] = useLessonWordLazyQuery({
    errorPolicy: "all",
    fetchPolicy: "cache-and-network",
  });

  const [getQuizStarter, { data: quizStarter }] =
    useStarterQuizProgressLazyQuery({
      errorPolicy: "all",
      fetchPolicy: "network-only",
    });

  const [getActivityTimedata, { data: activityElapsedTimeData }] =
    useTimeActivityLazyQuery({
      context: {
        headers: {
          Authorization: `Bearer ${keycloak.token}`, // Remplace `token` par la variable contenant ton token
        },
      },
      errorPolicy: "all",
      fetchPolicy: "cache-and-network",
    });

  const activityUrl = `${match.url}/${activity.__typename === "Quiz_2406" ? "quiz" : "lesson"
    }/${activity._id}`;

  /**
   * Open activity
   */
  const openActivity = (): void => {
    console.log("CURRENT DOMAIN", domain.gutenbergId);
    getQuizStarter({
      variables: {
        idOrPath: domain.gutenbergId,
      },
    });
  };

  useEffect(() => {
    if(quizStarter?.category?.quizzes?.length === 0){
      console.log('quizStarter' ,quizStarter)
      history.push(activityUrl);
    } else if (quizStarter?.category?.quizzes?.[0] && quizStarter.category.quizzes[0].bookmark) {
      if (
        quizStarter?.category?.quizzes[0]?.bookmark?.every(
          (bookmark) => bookmark.status !== BookmarkStatus.Done
        )
      ) {
        history.push({
          pathname: `/${domain.slug}/positionnement/${quizStarter.category.quizzes?.[0]._id}`,
          state: { starterInfo: true },
        })
      } else {
        history.push(activityUrl);
      }

    }
  }, [activityUrl, domain.slug, history, quizStarter, quizStarter?.category?.quizzes]);

  useEffect(() => {
    if(activity._id){
      setIsSuperAdmin(getIsSuperAdmin());
      getActivityTimedata({ variables: { id: activity._id } });
    }
  }, [activity._id, getActivityTimedata]);

  //! TODO a supprimer ?
  // useEffect(() => {
  //   if (activity._id === activitySelected) {
  //     const cardElement = document.getElementById(activity._id);
  //     if (cardElement) {
  //       setTimeout(() => {
  //         dispatchScroll({
  //           type: "setScrollPosition",
  //           scrollPosition: cardElement.getBoundingClientRect().top - 120,
  //         });
  //       }, 100);
  //     }
  //   }
  //   setIsSuperAdmin(getIsSuperAdmin());
  // }, [activity, activitySelected, dispatchScroll]);

  const progress = useMemo(() => {
    if (activityElapsedTimeData?.timev2?.duration) {
      const calculatedProgress = Math.floor(
        (activityElapsedTimeData.timev2.duration / activity.estimatedTime) * 100
      );

      return calculatedProgress < 100 ? calculatedProgress : 100;
    } else {
      return 0;
    }
  }, [activity.estimatedTime, activityElapsedTimeData]);

  /**
   * Get activity as word file
   */
  const fetchWordFile = async () => {
    setIsLoadingWordFile(true);
    if (activity.__typename === "Quiz_2406") {
      console.log("generation de quiz word");
      getQuizWord({
        variables: {
          slug: `${activity.slugV2}`,
        },
      });
    } else if (activity.__typename === "Lesson_2406") {
      console.log("generation de lesson word");
      getLessonWord({
        variables: {
          slug: `${activity.slugV2}`,
        },
      });
    }
  };

  useEffect(() => {
    if (quizWordData?.quizBySlug_2406) {
      getActivityWord2(
        quizWordData?.quizBySlug_2406,
        activityUrl,
        fetchWithCredentials
      )
        .catch(() => {
          setIsErrorWordFile(true);
        })
        .finally(() => setIsLoadingWordFile(false));
    }
    if (lessonWordData?.lessonBySlug_2406) {
      getActivityWord2(
        lessonWordData.lessonBySlug_2406,
        activityUrl,
        fetchWithCredentials
      )
        .catch(() => {
          setIsErrorWordFile(true);
        })
        .finally(() => setIsLoadingWordFile(false));
    }
  }, [
    quizWordData?.quizBySlug_2406,
    lessonWordData?.lessonBySlug_2406,
    activityUrl,
    fetchWithCredentials,
  ]);

  /**
   * Close snack bar
   */
  const handleClose = (
    _event: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setIsErrorWordFile(false);
  };

  return (
    <div
      onClick={() => locked && onLockedClick && onLockedClick()}
      className={classes.container}
    >
      <Grid
        id={activity._id}
        container={true}
        alignItems={"center"}
        spacing={2}
        wrap={"nowrap"}
      >
        <Grid item={true} className={classes.wrapper}>
          <div className={classes.wrapperContent}>
            <span className={classes.circulartext}>{progress}%</span>
            <CircularProgress
              size={50}
              variant="determinate"
              value={progress}
              thickness={4}
              className={classes.circular}
            />
            <CircularProgress
              size={50}
              variant="determinate"
              value={100}
              className={classes.circularBackground}
            />
          </div>
        </Grid>
        <Grid container={true} item={true} xs={true}>
          <Grid
            className={clsx({
              [classes.locked]: locked,
            })}
            item={true}
            md={true}
            xs={12}
            onClick={() => openActivity()}
          >
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Hidden mdUp={true} implementation="css">
                  <Grid
                    container={true}
                    alignItems={"center"}
                    direction="row"
                    justifyContent="flex-start"
                    spacing={2}
                  >
                    <Grid item={true}>
                      <Typography
                        noWrap={true}
                        className={classes.date}
                        color="textSecondary"
                      >
                        {formatStringToDate(activity.updatedAt)}
                      </Typography>
                    </Grid>
                    <Grid
                      item={true}
                      container={true}
                      xs={true}
                      justifyContent="flex-start"
                      alignItems={"center"}
                    >
                      <BorderLinearProgress
                        variant="determinate"
                        value={progress}
                        color="primary"
                      />
                      <Typography noWrap={true} className={classes.time}>
                        {progress}%
                      </Typography>
                    </Grid>
                  </Grid>
                </Hidden>
                <Grid container={true}>
                  <Grid item={true}>
                    <Chip
                      size="small"
                      label={domain.title}
                      className={classes.chipDomain}
                      style={{
                        backgroundColor: domain.color,
                      }}
                    />
                    {activity?.tags
                      .filter((tag) => tag !== "A_LA_UNE")
                      .map((tag, index) => (
                        <Chip
                          key={tag + index}
                          size="small"
                          label={cleanAccentLabel(tag)}
                          className={
                            NATURES.includes(tag)
                              ? classes.chipNature
                              : classes.chipType
                          }
                          variant="outlined"
                        />
                      ))}
                  </Grid>
                  <Grid
                    container={true}
                    item={true}
                    xs={true}
                    alignItems={"center"}
                    justifyContent={"flex-end"}
                  >
                    <Hidden smDown={true} implementation="css">
                      <Typography
                        noWrap={true}
                        className={classes.date}
                        color="textSecondary"
                      >
                        {formatStringToDate(activity.updatedAt)}
                      </Typography>
                    </Hidden>
                  </Grid>
                  <Grid item={true} xs={12}>
                    <Typography
                      className={classes.title}
                      color="textPrimary"
                      gutterBottom={true}
                    >
                      {activity.status === Status.Draft ||
                        activity.status === Status.Validated
                        ? "[BROUILLON] " + activity.title
                        : activity.title}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions className={classes.cardActions}>
                <Grid
                  container={true}
                  justifyContent="flex-start"
                  spacing={1}
                  className={classes.cardFooter}
                >
                  <Grid item={true} className={classes.clockIcon}>
                    <img src={clockIcon} alt="temps estimé" />
                  </Grid>
                  <Grid container={true} item={true} xs={9} alignItems="center">
                    <TimeIndicator2
                      color={domain.color}
                      isCategory={false}
                      confirmedTime={`${secondsToMinutes(
                        Math.min(
                          activityElapsedTimeData?.timev2?.duration ?? 0,
                          activity.estimatedTime
                        )
                      )} min`}
                      estimedTime={`${secondsToMinutes(
                        activity.estimatedTime ?? 0
                      )} min`}
                      isQuiz={false}
                    />
                  </Grid>

                  {
                    //!TODO  A GERER pour DEFINIR QUE C4EST UNE NOUVEAU  avec le tag a la une et pour le mise a jour je ne sais plus mais faut regarder je crois que UPDATE at et regarder par rapport a ça
                    /* {activity.state ? (
                    <Grid
                      className={classes.stateType}
                      container={true}
                      item={true}
                      xs
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      <Typography
                        className={classes.date}
                        gutterBottom={true}
                        noWrap={true}
                      >
                        <span
                          className={`${classes.dot} ${
                            activity.state === ActivityState.UPDATE
                              ? classes.dotUpdate
                              : classes.dotNew
                          }`}
                        />
                        {activity.state}
                      </Typography>
                    </Grid>
                  ) : null} */
                  }
                </Grid>
              </CardActions>
            </Card>
          </Grid>

          {/* {isSuperAdmin &&
            activity.__typename === "Lesson_2406" &&
            activity?.pdfs &&
            activity?.pdfs?.[0] &&
            activity.tags.every((tag) => tag !== "VIDEO") ? (
            <Grid
              item={true}
              md={3}
              xs={12}
              alignItems="stretch"
              container={true}
              onClick={() =>
                window.open(activity?.pdfs[0]?.okulusAsset.s3swhUrl, "_blank")
              }
            >
              <Paper className={classes.cardDownload}>
                <Grid
                  container={true}
                  alignContent={"center"}
                  alignItems={"center"}
                  className={classes.maxHeight}
                >
                  <Grid
                    container={true}
                    item={true}
                    xs={2}
                    md={3}
                    justifyContent="center"
                    className={classes.iconDownload}
                  >
                    {isLoadingWordFile ? (
                      <ColorCircularProgress />
                    ) : (
                      <GetAppIcon />
                    )}
                  </Grid>
                  <Grid
                    container={true}
                    item={true}
                    xs={9}
                    md={9}
                    justifyContent="center"
                    wrap="nowrap"
                  >
                    Télécharger le PDF
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ) : null} */}
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={isErrorWordFile}
        autoHideDuration={6000}
        onClose={handleClose}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={
          <span id="message-id">
            Il y a eu une erreur lors du téléchargement. Veuillez réessayer
            ultérieurement.
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </div>
  );
};

export default withRouter(CardActivity2);
