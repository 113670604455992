import hexToRgba from "hex-to-rgba";
import { CategoryStarterFragment } from "../graphql";
import {
  DEFAULT_DOMAIN,
  DomainColor,
  DomainId,
  DomainInfo,
  DOMAINS,
} from "../models/domain.model";

export const isMultipleDomains = (multiple: boolean): string => {
  return multiple ? "MES DOMAINES" : "MON DOMAINE";
};

//! TODO A SUPPPRIMER
export const getDomainById = (domainId: DomainId | number): DomainInfo => {
  return DOMAINS.find((d) => d.learningId === domainId) ?? DEFAULT_DOMAIN;
};

export const getDomainBySlug = (slug: string): DomainInfo => {
  return DOMAINS.find((d) => d.slug === slug) ?? DEFAULT_DOMAIN;
};

export const getDomainFromParent = (
  parent?: CategoryStarterFragment | null
): DomainInfo =>
  parent ? getDomainFromPath(`${parent.path},${parent.slug}`) : DEFAULT_DOMAIN;

export const getDomainFromPath = (path: string): DomainInfo =>
  DOMAINS.find((domain) => path.startsWith(domain.fullPath)) ?? DEFAULT_DOMAIN;

//! TODO A SUPPPRIMER
export const getDomainColor = (domain: DomainInfo, alpha?: number): string =>
  alpha
    ? hexToRgba(DomainColor[domain.learningId], alpha)
    : DomainColor[domain.learningId];
