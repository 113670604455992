// React
import React, { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";

// Material
import { Card, CardContent, Grid, Hidden, Typography } from "@material-ui/core";

// Styles
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

// Helpers
import { isPlurial } from "../../helpers/card.helper";
import clsx from "clsx";

// Models
import { Category } from "../../models/category.model";

// Colors
import {
  useSubjectDispatch,
  useSubjectState,
} from "../../views/Subject/Subject.provider";
import { useScrollDispatch } from "../../shared/Scroll.provider";

import { getDomainColor } from "../../helpers/domain.helper";
import { ActivityState } from "../../models/Activity.model";
import { yellow } from "@material-ui/core/colors";
import { Status, ThemeCardFragment } from "../../graphql";
import { DOMAINS } from "../../models/domain.model";

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      borderLeft: "5px solid",
      borderRadius: 0,
      boxShadow: "none",
    },
    cardContent: {
      padding: 8,
      "&:last-child": {
        paddingBottom: 8,
      },
    },
    text: {
      cursor: "pointer",
      textDecoration: "none",
      color: "black",
    },
    title: {
      minHeight: 22,
      fontSize: 16,
      justifyContent: "center",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    subtitle: {
      height: 18,
      letterSpacing: 0.86,
      fontSize: 12,
      justifyContent: "center",
    },
    container: {
      width: "100%",
    },
    dotCardSubject: {
      margin: 2,
      marginRight: 10,
      width: 8,
      height: 8,
      borderRadius: "50%",
      display: "inline-block",
      border: "solid 1.6px",
    },
    dot: {
      margin: 1,
      marginRight: 2,
      width: 8,
      height: 8,
      borderRadius: "50%",
      display: "inline-block",
      backgroundColor: theme.palette.primary.main,
    },
    dotUpdate: {
      backgroundColor: yellow[600],
    },
    dotNew: {
      backgroundColor: theme.palette.primary.main,
    },
    locked: {
      pointerEvents: "none",
      opacity: 0.5,
      cursor: "not-allowed",
    },
  })
);

/**
 * CardSubject props
 */
interface CardSubjectProps {
  category: ThemeCardFragment;
  domainSlug: string;
  locked?: boolean;
  onLockedClick?: () => void;
}

/**
 * Card subject
 */
const CardSubject = ({
  category,
  domainSlug,
  locked,
  onLockedClick,
}: CardSubjectProps) => {
  const classes = useStyles();

  const colorDomain = useMemo(() => {
    return DOMAINS.find((currentDomain) => currentDomain.slug === domainSlug)
      .color;
  }, [domainSlug]);

  /** If state of category is present change size */
  //const sizeCategory = category.state ? 9 : 11; //!TODO A voir si on garde

  /** Subject provider */
  const dispatch = useSubjectDispatch();
  const { subjectSelected } = useSubjectState();

  /** Scroll Provider */
  const dispatchScroll = useScrollDispatch();

  const openCategory = (): void => {
    dispatch({
      type: "setIdSubjectSelected",
      idSubjectSelected: category._id,
    });
  };

  useEffect(() => {
    if (subjectSelected && subjectSelected === category._id) {
      const cardElement = document.getElementById(category._id.toString());
      if (cardElement) {
        setTimeout(() => {
          dispatchScroll({
            type: "setScrollPosition",
            scrollPosition: cardElement.getBoundingClientRect().top - 150,
          });
        }, 100);
      }
    }
  }, [category._id, dispatchScroll, subjectSelected]);

  return (
    <div
      onClick={() => locked && onLockedClick && onLockedClick()}
      className={clsx({
        [classes.container]: true,
      })}
    >
      <Grid
        className={clsx({
          [classes.locked]: locked,
        })}
        container={true}
        alignItems={"center"}
        spacing={2}
        wrap={"nowrap"}
      >
        <Grid item={true} xs={true}>
          <Card
            className={classes.card}
            style={{ borderLeftColor: colorDomain }}
          >
            <CardContent className={classes.cardContent}>
              <Grid container={true}>
                <Grid item={true} xs={9}>
                  <Grid
                    container={true}
                    direction="row"
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item={true}>
                      <Typography className={classes.title} color="textPrimary">
                        <span
                          className={classes.dotCardSubject}
                          style={{
                            borderColor: colorDomain,
                          }}
                        />
                        <Link
                          className={classes.text}
                          id={category._id}
                          to={`/${domainSlug}/competences/${category._id}`}
                          onClick={openCategory}
                        >
                          {category.status === Status.Draft
                            ? "[BROUILLON]"
                            : null}{" "}
                          {category.title}
                        </Link>
                      </Typography>
                    </Grid>
                    <Grid item={true}>
                      <Typography
                        className={`${classes.subtitle}`}
                        color="textSecondary"
                        noWrap={true}
                      >
                        (
                        {category.recursiveCounts.lessons +
                          category.recursiveCounts.quizzes}{" "}
                        {isPlurial(
                          category.recursiveCounts.lessons +
                            category.recursiveCounts.quizzes
                        )}
                        )
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item={true} xs={3}>
                  <Grid container={true} justifyContent="center">
                    {/*
                    //!TODO a faire
                    // <Hidden smDown={true} implementation="css">
                      {category.state ? (
                        <Typography color="textSecondary" noWrap={true}>
                          <span
                            className={`${classes.dot} ${
                              category.state === ActivityState.UPDATE
                                ? classes.dotUpdate
                                : classes.dotNew
                            }`}
                          />
                          {category.state}
                        </Typography>
                      ) : null}
                    </Hidden> */}
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default React.memo(CardSubject);
