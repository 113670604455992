import { CONST } from "../config/constant";

/**
 * Domain interface
 */
export interface DomainInfo {
  learningId: DomainId;
  title: string;
  slug: string;
  fullPath: string;
  gutenbergId: string;
  tag: string;
  color: string;
  test: string;
}

export const DOMAINS: DomainInfo[] = [
  {
    title: "Comptabilité",
    slug: "comptabilite",
    fullPath: ",grf,formations,contenu-premium,812",
    learningId: 812,
    test: "DOMAIN_ACCOUNTING",
    gutenbergId: CONST.DOMAIN_ACCOUNTING,
    tag: "COMPTABILITE",
    color: "#f9cc00",
  },
  {
    title: "Fiscalité des entreprises",
    slug: "fiscalite-des-entreprises",
    fullPath: ",grf,formations,contenu-premium,930",
    gutenbergId: CONST.DOMAIN_TAX,
    learningId: 930,
    test: "DOMAIN_TAX",
    tag: "FISCALITE DES ENTREPRISES",
    color: "#0572b1",
  },
  {
    title: "Droit du travail",
    slug: "droit-du-travail",
    fullPath: ",grf,formations,contenu-premium,1073",
    gutenbergId: CONST.DOMAIN_SOCIAL,
    learningId: 1073,
    test: "DOMAIN_SOCIAL",
    tag: "DROIT DU TRAVAIL",
    color: "#e51049",
  },
  {
    title: "Gestion de la paye",
    slug: "gestion-de-la-paye",
    fullPath: ",grf,formations,contenu-premium,1123",
    gutenbergId: CONST.DOMAIN_PAYROLL,
    test: "DOMAIN_PAYROLL",
    learningId: 1123,
    tag: "GESTION DE LA PAIE",
    color: "#ec6d18",
  },
  {
    title: "Droit des affaires",
    slug: "droit-des-affaires",
    fullPath: ",grf,formations,contenu-premium,1203",
    gutenbergId: CONST.DOMAIN_BUSINESS,
    test: "DOMAIN_BUSINESS",
    learningId: 1203,
    tag: "DROIT DES AFFAIRES",
    color: "#07ad95",
  },
  {
    title: "Gestion de patrimoine",
    slug: "gestion-de-patrimoine",
    fullPath: ",grf,formations,contenu-premium,1374",
    gutenbergId: CONST.DOMAIN_HERITAGE,
    test: "DOMAIN_HERITAGE",
    learningId: 1374,
    tag: "GESTION DE PATRIMOINE",
    color: "#c35ea0",
  },
  {
    title: "Performance du cabinet",
    slug: "performance-du-cabinet",
    fullPath: ",grf,formations,contenu-premium,1380",
    gutenbergId: CONST.DOMAIN_OFFICE,
    test: "DOMAIN_OFFICE",
    learningId: 1380,
    tag: "PERFORMANCE DU CABINET",
    color: "#7ebc4e",
  },
];

export const DEFAULT_DOMAIN: DomainInfo = {
  learningId: 0,
  title: "Domaine",
  slug: "domaine",
  fullPath: ",grf,formations,contenu-premium",
  test: "",
  gutenbergId: "",
  tag: "DOMAINE",
  color: "#fffff",
};

export enum DomainId {
  DOMAIN_ACCOUNTING = 812,
  DOMAIN_TAX = 930,
  DOMAIN_SOCIAL = 1073,
  DOMAIN_PAYROLL = 1123,
  DOMAIN_BUSINESS = 1203,
  DOMAIN_HERITAGE = 1374,
  DOMAIN_OFFICE = 1380,
  DEFAULT = 0,
}
//! TODO A SUPPRIMER si il y a le nouveau qui n'utilise plus les ID
export enum DomainColor {
  "#f9cc00" = 812,
  "#0572b1" = 930,
  "#e51049" = 1073,
  "#ec6d18" = 1123,
  "#07ad95" = 1203,
  "#c35ea0" = 1374,
  "#7ebc4e" = 1380,
}
