// React
import React from "react";
// Material
import { Box, Button, Grid, Theme, Typography } from "@material-ui/core";
// Styles
import { createStyles, makeStyles } from "@material-ui/styles";
// Model
import { Quiz } from "../../../graphql";
import spacerBilan from "../assets/spacer-note.svg";

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bilan: {
      background: "#f1f4f5",
      textAlign: "center",
      position: "relative",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    title: {
      fontSize: "15vw",
      fontWeight: 800,
      color: "#ebedee",
      margin: "0 auto",
      width: "100%",
    },
    subTitle: {
      fontSize: 28,
      fontWeight: 900,
      color: "#18202f",
    },
    correctionScore: {
      fontSize: 57.9,
      lineHeight: 1.2,
    },
    ratio: {
      color: theme.palette.primary.main,
      fontWeight: 700,
    },
    separatorScore: {
      width: 67.9,
      height: 21.1,
      background: "url('./assets/spacer-note.svg') no-repeat",
      margin: "auto",
    },
    correctionComment: {
      fontSize: 20,
      lineHeight: 1.1,
      textAlign: "center",
      color: "#18202f",
    },
    groupButton: {
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      boxSizing: "border-box",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    button: {
      width: 200,
      fontWeight: 700,
    },
    buttonRetry: {
      color: "white",
    },
    buttonLeave: {
      backgroundColor: "white",
    },
  })
);

/**
 * Bilan props
 */
interface IBilanProps {
  quiz: Quiz;
  score: number | null;
  handleClose: () => void;
  handleRestart: () => void;
}

/**
 * Bilan Component
 */
const Bilan = ({ quiz, score, handleClose, handleRestart }: IBilanProps) => {
  /** classes */
  const classes = useStyles();

  return (
    <Box className={classes.bilan}>
      <Typography
        component="h1"
        variant="h4"
        align="center"
        gutterBottom={true}
        className={classes.title}
      >
        BILAN
      </Typography>
      <Typography
        component="h2"
        align="center"
        gutterBottom={true}
        className={classes.subTitle}
      >
        {score === quiz.maximalScore ? "Bravo! " : null}
        Vous avez eu:
      </Typography>
      <div className={classes.correctionScore}>
        <Grid
          container={true}
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <div>
            <span className={classes.ratio}>{score}</span>/{quiz.maximalScore}
          </div>
          <img src={spacerBilan} alt="" className={classes.separatorScore} />
        </Grid>
        <div />
        {(score ?? 0) < quiz.maximalScore ? (
          <p className={classes.correctionComment}>
            C'est bien, mais vous <br />
            pouvez faire encore mieux!
          </p>
        ) : (
          <p className="correction-comment">C'est un sans faute!</p>
        )}
        <Grid className={classes.groupButton}>
          <Box m={1}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleRestart}
              className={`${classes.button} ${classes.buttonRetry}`}
            >
              Recommencer
            </Button>
          </Box>
          <Box m={1}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleClose}
              className={`${classes.button} ${classes.buttonLeave}`}
            >
              Quitter l'activité
            </Button>
          </Box>
        </Grid>
      </div>
    </Box>
  );
};

export default React.memo(Bilan);
