import React, { useEffect, useMemo } from "react";
import { withRouter } from "react-router";
import { Helmet } from "react-helmet";

// Components
import { FakeCardActivity } from "../../features/CardActivity/CardActivity";

import {
  LessonNewsDataFragment,
  QuizNewsDataFragment,
  useCategorySubjectLazyQuery,
} from "../../graphql";

import { Box } from "@material-ui/core";
import CardActivity2 from "../../features/CardActivity/CardActivity2";
import NoResult from "../../features/NoResult/NoResult";

/**
 * Subject component
 */
const Subject = withRouter(({ match }) => {
  /** GraphQL */
  const [getSubject, { data, loading, error }] = useCategorySubjectLazyQuery({
    errorPolicy: "ignore",
    fetchPolicy: "cache-and-network",
  });

  const flattenedData = useMemo(() => {
    if (!data?.category) return [];

    const seenIds = new Set<string>(); // Stocke les _id uniques

    const flatten = (
      node: any
    ): (LessonNewsDataFragment | QuizNewsDataFragment)[] =>
      (node.childrenDataV2 ?? [])
        .reduce(
          (acc, child) => [...acc, ...flatten(child)],
          [...(node.lessons_2406 ?? []), ...(node.quizzes_2406 ?? [])].filter(
            (item) => {
              if (seenIds.has(item._id)) return false; // Si déjà vu, on ignore
              seenIds.add(item._id); // Sinon, on l'ajoute et on garde l'élément
              return true;
            }
          )
        )
        .sort(
          (a, b) =>
            new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
        );

    return flatten(data.category);
  }, [data?.category]);

  /** Match */
  const { params } = match;

  useEffect(() => {
    getSubject({
      variables: {
        idOrPath: params.subjectId,
      },
    });
  }, [getSubject, params.subjectId]);

  return (
    <React.Fragment>
      {data?.category?.title ? (
        <Helmet defer={false}>
          <meta charSet="utf-8" />
          <title>{data.category.title}</title>
        </Helmet>
      ) : null}
      {loading ? (
        <Box marginTop={3}>
          <FakeCardActivity />
        </Box>
      ) : !loading && error ? (
        <p>
          Cette ressource est inacessible pour le moment, veuillez reassayer
          dans un court instant
        </p>
      ) : (
        <>
          {flattenedData.length > 0 ? (
            flattenedData.map((activity) => {
              return (
                <Box id={activity._id} key={activity._id} marginTop={1}>
                  <CardActivity2 activity={activity} />
                </Box>
              );
            })
          ) : (
            <NoResult />
          )}
        </>
      )}
    </React.Fragment>
  );
});

export default Subject;
