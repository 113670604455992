// React
import { useEffect, useMemo } from "react";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

// Material
import { makeStyles, Grid, Box, Theme } from "@material-ui/core";
import CardActivity2 from "../../features/CardActivity/CardActivity2";

// Query
import { DOMAINS } from "../../models/domain.model";
import { getDomainBySlug } from "../../helpers/domain.helper";
import {
  LessonNewsDataFragment,
  QuizNewsDataFragment,
  useNewsDomainLazyQuery,
} from "../../graphql";
import { canAccessDomain } from "../../services/user.service";
import { FakeCardActivity } from "../../features/CardActivity/CardActivity";
import NoResult from "../../features/NoResult/NoResult";
import { sortActivities } from "../../services/activity.service";

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "10%",
      paddingRight: "10%",
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    "@supports (-webkit-touch-callout: none)": {
      marginBottom: 149,
    },
  },
  bandeau: {
    width: "100%",
    marginBottom: 12,
  },
}));
/**
 * News component
 */
const News = withRouter(({ match }) => {
  const classes = useStyles();
  const [getDomainNews, { data: domainData, loading, error }] =
    useNewsDomainLazyQuery({
      errorPolicy: "ignore",
      fetchPolicy: "cache-and-network",
    });

  const flattenedData = useMemo(() => {
    if (!domainData) return [];

    const seenIds = new Set<string>(); // Stocke les _id uniques

    const flatten = (
      node: any
    ): (LessonNewsDataFragment | QuizNewsDataFragment)[] =>
      (node.childrenDataV2 ?? [])
        .reduce(
          (acc, child) => [...acc, ...flatten(child)],
          [...(node.lessons_2406 ?? []), ...(node.quizzes_2406 ?? [])].filter(
            (item) => {
              if (seenIds.has(item._id)) return false; // Si déjà vu, on ignore
              seenIds.add(item._id); // Sinon, on l'ajoute et on garde l'élément
              return true;
            }
          )
        )
        .sort(
          (a, b) =>
            new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
        );

    return flatten(domainData.category);
  }, [domainData]);

  //! TODO STARTER QUIZ LIST
  // const starterQuizList = useSelector(
  //   (state: StoreState) => state.starterQuizList
  // );

  //! TODO gestion du user
  /** User state */

  /** show locked notification state */
  //const [showLockedNotification, setShowLockedNotification] = useState(false);

  /**
   * Use effect activity list
   */
  /*   useEffect(() => {
    ((activities && activities.length > 0) || activities === null) &&
      setLoadingActivities(false);
  }, [activities]); */

  useEffect(() => {
    getDomainNews({
      variables: {
        idOrPath: `,grf,formations,contenu-premium,${
          DOMAINS.find((domain) => domain.slug === match.params.domainSlug)
            ?.learningId
        }`,
      },
    });
  }, [getDomainNews, match.params.domainSlug]);

  return (
    <div>
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <title>À la une</title>
      </Helmet>
      <Grid
        container={true}
        direction="column"
        spacing={2}
        className={classes.container}
      >
        {/*  <img
        src={bandeau}
        alt="Nous vous souhaitons bonne fete"
        className={classes.bandeau}
      /> */}
      </Grid>
      {loading ? (
        <Box marginTop={3}>
          <FakeCardActivity />
        </Box>
      ) : !loading && error ? (
        <p>
          Cette ressource est inacessible pour le moment, veuillez reassayer
          dans un court instant
        </p>
      ) : (
        <>
          {flattenedData.length > 0 ? (
            flattenedData.map((activity) => {
              return (
                <Box id={activity._id} key={activity._id} marginTop={1}>
                  <CardActivity2 activity={activity} />
                </Box>
              );
            })
          ) : (
            <NoResult />
          )}
        </>
      )}
    </div>
  );
});

export default News;
