// React
import React, { useEffect, useState, memo } from "react";
import { withRouter } from "react-router-dom";

import ContentLoader from "react-content-loader";
import { Helmet } from "react-helmet";

// Material
import { createStyles, makeStyles, Theme } from "@material-ui/core";

// Components
import BaseSkillsItem from "../../features/BaseSkillsItem/BaseSkillsItem";
import ActivityTime from "../../features/ActivityTime/ActivityTime";

// Keycloak
import Box from "@material-ui/core/Box";
import LockedBanner from "../../features/LockedBanner/LockedBanner";

// GraphQl
import {
  BookmarkStatus,
  Status,
  ThemeCardFragment,
  useBaseSkill2LazyQuery,
  useStarterQuizProgressLazyQuery,
} from "../../graphql";

import {
  canAccessDomainBySlug,
  getIsSuperAdmin,
} from "../../services/user.service";

import { DEFAULT_DOMAIN, DOMAINS } from "../../models/domain.model";
import QuizStarterCard from "../Quiz/QuizStarterCard";

// TODO a supprimer ?
// const getCategoryToOpen = (
//   categoryList: Category[],
//   idSubjectSelected: string
// ): any => {
//   const categoryRootSelected: Category = getCategoryRoot(
//     categoryList,
//     idSubjectSelected
//   );

//   return categoryRootSelected ? categoryRootSelected : -1;
// };

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      [theme.breakpoints.up("md")]: {
        paddingRight: 100,
        paddingLeft: 100,
      },
    },
  })
);
/**
 * BaseSkills component
 */
const BaseSkills = withRouter(({ match }) => {
  /** GraphQL */
  const [getBaseSkill, { data }] = useBaseSkill2LazyQuery({
    errorPolicy: "ignore",
    fetchPolicy: "cache-and-network",
  });
  /** Classes */
  const classes = useStyles();
  /** Current domain */

  const domain =
    DOMAINS.find((domain) => match.params.domainSlug.includes(domain.slug)) ??
    DEFAULT_DOMAIN;

  /** Category list state */
  //const [category, setCategory] = useState<Category>();
  /** Use subject */
  // const { subjectSelected } = useSubjectState();
  /** loading state */
  const [loading, setLoading] = useState(true);
  const [disabledDomain, setDisabledDomain] = useState(false);
  /** Category list opened */
  // const [categoryListOpened, setCategoryListOpened] = useState<Category[]>([]);
  /** show locked notification state */
  const [showLockedNotification, setShowLockedNotification] = useState(false);

  const [getQuizStarter, { data: quizStarter }] =
    useStarterQuizProgressLazyQuery({
      errorPolicy: "all",
      fetchPolicy: "network-only",
    });
  /**
   * BaseSkillLoader
   */
  const BaseSkillLoader = () => (
    <ContentLoader
      height={160}
      width={700}
      speed={2}
      color="#f3f3f3"
      backgroundColor="#e3e3e3"
    >
      <rect x="20" y="10" rx="5" ry="5" width="650" height="25" />
      <rect x="20" y="40" rx="5" ry="5" width="650" height="25" />
      <rect x="20" y="70" rx="5" ry="5" width="650" height="25" />
    </ContentLoader>
  );

  /**
   * Initial useEffect
   */
  useEffect(() => {
    console.log("domaine ???", domain);
    if (domain && canAccessDomainBySlug(domain.slug)) {
      console.log("Appl baseSkill");
      getQuizStarter({
        variables: {
          idOrPath: domain.gutenbergId,
        },
      });
      getBaseSkill({
        variables: {
          idOrPath: `,grf,formations,contenu-premium,${
            DOMAINS.find((domain) => domain.slug === match.params.domainSlug)
              ?.learningId
          }`,
        },
      });
    } else {
      setDisabledDomain(true);
      console.log("pas d acces a ce domaine");
      //? TODO Gestion lorsque l'utilisateur n'a pas les droits pour ce domaine
    }
  }, [domain, getBaseSkill, getQuizStarter, match.params.domainSlug]);

  useEffect(() => {
    if (data?.category) {
      setLoading(false);
    }
  }, [data]);

  const activateLockedNotification = () => {
    setShowLockedNotification(true);
    setTimeout(() => {
      setShowLockedNotification(false);
    }, 7500);
  };

  const isSuperAdmin = getIsSuperAdmin();

  /**
   * Use effect scroll page
   */
  //! gestion de l'ouverture par anchor a faire
  // useEffect(() => {
  //   if (category?.childrensMapped) {
  //     const newCategoryListOpened: Category[] = [];
  //     if (
  //       category.childrensMapped.length &&
  //       subjectSelected &&
  //       subjectSelected !== -1
  //     ) {
  //       newCategoryListOpened.push(
  //         getCategoryToOpen(category.childrensMapped, subjectSelected)
  //       );

  //       while (
  //         newCategoryListOpened[newCategoryListOpened.length - 1]
  //           .childrensMapped
  //       ) {
  //         newCategoryListOpened.push(
  //           getCategoryToOpen(
  //             newCategoryListOpened[newCategoryListOpened.length - 1]
  //               .childrensMapped!,
  //             subjectSelected
  //           )
  //         );
  //       }
  //     }
  //     setCategoryListOpened(newCategoryListOpened);
  //   }
  // }, [category, subjectSelected]);

  return (
    <div id="baseSkills">
      {showLockedNotification && <LockedBanner />}
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <title>{`${data?.category?.title} - Socle de compétences`}</title>
      </Helmet>
      {!disabledDomain ? (
        <div className={classes.container}>
          {loading || !data.category ? (
            <BaseSkillLoader />
          ) : (
            <React.Fragment>
              <Box marginBottom={20}>
                <ActivityTime
                  isActivityList={false}
                  domain={domain}
                  confirmedTime={data?.category?.elapsedTime?.duration}
                  globalTime={0} //! TODO , comment recuperer les informmtions ( Axel feature)
                  globalTimeTitleCustom={"Temps global du domaine"}
                />

                {quizStarter?.category?.quizzes?.length > 0 &&
                  quizStarter?.category?.quizzes?.[0]._id &&
                  quizStarter?.category?.quizzes[0]?.bookmark?.every(
                    (bookmark) => bookmark.status !== BookmarkStatus.Done
                  ) && (
                    <div style={{ marginBottom: 15 }}>
                      <QuizStarterCard
                        id={quizStarter?.category.quizzes?.[0]._id}
                        domain={domain}
                      />
                    </div>
                  )}
                {data.category?.childrenDataV2
                  .filter((category) =>
                    !isSuperAdmin
                      ? category.status === Status.Published &&
                        category.recursiveCounts.lessons +
                          category.recursiveCounts.quizzes >
                          0
                      : category.status === Status.Published ||
                        category.status === Status.Draft
                  )
                  .map((category: ThemeCardFragment) => (
                    <div id={category._id} key={category._id}>
                      <BaseSkillsItem
                        key={category._id}
                        category={category}
                        domainSlug={domain.slug}
                        locked={false} //! TODO , GERER l'affichage sur StarterQuiz
                        onLockedClick={activateLockedNotification}
                      />
                    </div>
                  ))}
              </Box>
            </React.Fragment>
          )}
        </div>
      ) : (
        <p>Vous n'avez pas les droits pour acceder a ce contenu</p>
      )}
    </div>
  );
});

export default memo(BaseSkills);
