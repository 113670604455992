// React
import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { useLocation, withRouter } from "react-router";

// Material
import {
  Grid,
  Box,
  Zoom,
  List,
  ListItem,
  MenuItem,
  Menu,
  Container,
  Typography,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

// Styles
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

// Components
import CardDashboard from "../../features/CardDashboard/CardDashboard";
import NoResult from "../../features/NoResult/NoResult";
import CertificatePicker from "../../features/CertificatePicker/CertificatePicker";

// Models
import { User } from "../../models/user.model";

// Providers
import { useScrollDispatch } from "../../shared/Scroll.provider";

// Store
import { useSelector } from "react-redux";
import { StoreState } from "../../store";

// Helpers
import {
  getFirstDateOfYear,
  getLastDateOfYear,
  getTimestamp,
} from "../../helpers/date.helper";

// Graphql
import { getSecondsFormattedOnlyHours } from "../../helpers/date-fomatter.helper";
import SurveyModal from "../../features/SurveyModal/SurveyModal";
import { DomainInfo } from "../../models/domain.model";
import { getDomainById } from "../../helpers/domain.helper";
import { useTimePerDomainLazyQuery } from "../../graphql";
import { CONST } from "../../config/constant";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "grid",
      gridTemplateColumns: "repeat(2, 1fr)",
      gridAutoRows: "1fr",
      gridColumnGap: 15,
      gridRowGap: 15,
      margin: "0 auto",
      [theme.breakpoints.down("sm")]: {
        paddingBottom: 100,
        gridTemplateColumns: "repeat(1, 1fr)",
      },
    },
    control: {
      padding: theme.spacing(2),
    },
    cssOutlinedInput: {
      "&$cssFocused $notchedOutline": {
        borderColor: "black",
      },
    },
    cssFocused: {},
    notchedOutline: {},
    certificationLabel: {
      [theme.breakpoints.up("sm")]: {
        flex: "grow",
        flexGrow: 1,
      },
    },
    title: {
      marginLeft: -2,
      color: "#18202f",
      fontWeight: "bold",
      fontSize: 16,
      [theme.breakpoints.down("md")]: {
        marginLeft: 0,
      },
    },
    totalLabel: {
      fontWeight: 600,
      color: "#263c46",
      fontSize: 13,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        textAlign: "center",
      },
    },
    certificationSelectBloc: {
      marginLeft: -2,
      alignItems: "center",
      display: "flex",
      [theme.breakpoints.down("md")]: {
        marginRight: -7,
        width: "100%",
      },
    },
    certificationSelectList: {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    certificationSelectButton: {
      textTransform: "none",
      border: "1px solid #c3c6cc",
      backgroundColor: "white",
      borderRadius: 5,
      fontSize: 14,
    },
    cardList: {
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    selectItem: {
      minHeight: 20,
      "&:hover": {
        backgroundColor: "gainsboro",
      },
    },
    selectedItem: {
      backgroundColor: "silver",
    },
    buttonDownload: {
      borderRadius: 5,
      height: 53,
    },
  })
);

/**
 * Dashboard component
 */
const Dashboard = () => {
  /** Classes */
  const classes = useStyles();
  /** GraphQL */
  const [getTimePerDomain, { data, error }] = useTimePerDomainLazyQuery({
    errorPolicy: "all",
    fetchPolicy: "network-only",
  });
  /** Props */
  const location = useLocation<{ colleague: User }>();
  /** Loading state */
  const [loading, setLoading] = useState(true);
  /** Selected year */
  const [selectedYear, setSelectedYear] = useState<number>();
  const [selectedYearDomains, setSelectedYearDomains] = useState<number[]>([]);
  /** Available years list */
  const [availableYears, setAvailableYears] = useState<number[]>();
  /** Times */
  const [timeTotal, setTimeTotal] = useState<number>(0);
  const [timesPerDomain, setTimesPerDomain] = useState<
    { domain: DomainInfo; duration: number }[]
  >([]);
  /** Scroll Provider */
  const dispatchScroll = useScrollDispatch();
  /** User state */
  const user: User | null = useSelector(
    (state: StoreState) => location?.state?.colleague ?? state.user
  );

  /** titleCustom */
  const [titleCustom, setTitleCustom] = useState<string>("");
  /** Menu anchor */
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  /** Fake Placeholder Card List */
  const fakePlacehoderCardList: number[] = [0, 1, 2, 3];

  /**
   * Manage scroll
   */
  useEffect(() => {
    dispatchScroll({ type: "setScrollPosition", scrollPosition: 1 });
  }, []);

  /**
   * Retreive list of years
   */
  useEffect(() => {
    if (!user || user.subscription.pastDomainAccessEl.length === 0) {
      setSelectedYear(new Date().getFullYear());
      setAvailableYears([new Date().getFullYear()]);
      setTitleCustom(
        "Cet utilisateur n'a pas encore passé du temps de formation réalisé sur une activité"
      );
    } else {
      setSelectedYear(user.subscription.pastDomainAccessEl[0].year);
      setAvailableYears(
        user.subscription.pastDomainAccessEl.map((p) => p.year)
      );
    }
  }, [location.pathname, user]);

  /**
   * Get data for dashboard
   */
  useEffect(() => {
    if (selectedYear) {
      setLoading(true);
      getTimePerDomain({
        variables: {
          userId: user?._id,
          startTimestamp: getTimestamp(getFirstDateOfYear(selectedYear)),
          endTimestamp: getTimestamp(getLastDateOfYear(selectedYear)),
          DOMAIN_ACCOUNTING: CONST.DOMAIN_ACCOUNTING,
          DOMAIN_TAX: CONST.DOMAIN_TAX,
          DOMAIN_SOCIAL: CONST.DOMAIN_SOCIAL,
          DOMAIN_PAYROLL: CONST.DOMAIN_PAYROLL,
          DOMAIN_BUSINESS: CONST.DOMAIN_BUSINESS,
          DOMAIN_HERITAGE: CONST.DOMAIN_HERITAGE,
          DOMAIN_OFFICE: CONST.DOMAIN_OFFICE,
        },
      });
      setSelectedYearDomains(
        user?.subscription.pastDomainAccessEl.find(
          (p) => p.year === selectedYear
        )?.domainsIds ?? []
      );
    }
  }, [
    getTimePerDomain,
    selectedYear,
    user?._id,
    user?.subscription.pastDomainAccessEl,
  ]);

  useEffect(() => {
    if (data) {
      const _timesPerDomain = [
        {
          domain: getDomainById(812),
          duration: data.DOMAIN_ACCOUNTING?.duration ?? 0,
        },
        {
          domain: getDomainById(930),
          duration: data.DOMAIN_TAX?.duration ?? 0,
        },
        {
          domain: getDomainById(1073),
          duration: data.DOMAIN_SOCIAL?.duration ?? 0,
        },
        {
          domain: getDomainById(1123),
          duration: data.DOMAIN_PAYROLL?.duration ?? 0,
        },
        {
          domain: getDomainById(1203),
          duration: data.DOMAIN_BUSINESS?.duration ?? 0,
        },
        {
          domain: getDomainById(1374),
          duration: data.DOMAIN_HERITAGE?.duration ?? 0,
        },
        {
          domain: getDomainById(1380),
          duration: data.DOMAIN_OFFICE?.duration ?? 0,
        },
      ].filter((timeForDomain) =>
        selectedYearDomains.includes(timeForDomain.domain.learningId)
      );
      setTimesPerDomain(_timesPerDomain);
      setTimeTotal(
        _timesPerDomain.reduce(
          (total, timeForDomain) => total + timeForDomain.duration,
          0
        )
      );
      setLoading(false);
    }
  }, [data]);

  useEffect(() => {
    if (error) setLoading(false);
  }, [error]);

  /**
   * Handle click to open menu
   */
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * Handle click on menu item
   */
  // tslint:disable-next-line: variable-name
  const handleMenuItemClick = (
    _event: React.MouseEvent<HTMLElement>,
    year: number
  ) => {
    setAnchorEl(null);
    setSelectedYear(year);
  };

  /**
   * Handle menu close
   */
  const handleClose = () => {
    setAnchorEl(null);
  };

  /** wrapperRef */
  const wrapperRef = useRef(null);

  /**
   * Hook that alerts clicks outside of the passed ref
   * @param ref
   */
  const useOutsideAlerter = (refLabel: any) => {
    /**
     * Hide menu if clicked on outside of element
     */
    const handleClickOutside = (event: any) => {
      if (refLabel.current && !refLabel.current.contains(event.target)) {
        handleClose();
      }
    };

    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    });
  };

  useOutsideAlerter(wrapperRef);

  return (
    <Box marginLeft={-2} marginRight={-2}>
      {location && !location.state?.colleague && <SurveyModal />}
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <title>Mon suivi</title>
      </Helmet>
      {user && <CertificatePicker user={user} />}
      <Container style={{ margin: "0 auto" }} maxWidth="md">
        {!loading ? (
          <>
            <Typography
              component="p"
              className={classes.title}
              gutterBottom={true}
              noWrap={true}
            >
              Mon suivi
            </Typography>
            <Grid
              container={true}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <span className={classes.certificationSelectBloc}>
                <List
                  component="nav"
                  ref={wrapperRef}
                  className={classes.certificationSelectList}
                >
                  <ListItem
                    button={true}
                    onClick={handleClickListItem}
                    className={classes.certificationSelectButton}
                  >
                    Temps de formation réalisé en {selectedYear}&nbsp;
                    <ArrowDropDownIcon />
                  </ListItem>
                </List>
                <Menu
                  id="lock-menu"
                  anchorEl={anchorEl}
                  keepMounted={true}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  {availableYears !== undefined
                    ? availableYears.map((year) => (
                        <MenuItem
                          key={year}
                          value={year}
                          className={`${classes.selectItem} ${
                            selectedYear === year ? classes.selectedItem : null
                          }`}
                          onClick={(event) => handleMenuItemClick(event, year)}
                        >
                          Temps de formation réalisé en {year}
                        </MenuItem>
                      ))
                    : null}
                </Menu>
              </span>
              <Typography
                component="p"
                className={classes.totalLabel}
                gutterBottom={true}
                noWrap={true}
              >
                Total
                {location.state?.colleague
                  ? ` pour l'utilisateur ${location.state?.colleague.firstName} ${location.state?.colleague.lastName}`
                  : null}
                {" : "}
                {getSecondsFormattedOnlyHours(timeTotal)}
              </Typography>
            </Grid>
          </>
        ) : null}
        <Box m={2} />
        {!loading && selectedYear ? (
          <Grid container={true} className={classes.container} spacing={2}>
            {timesPerDomain.length ? (
              timesPerDomain.map(({ domain, duration }, index) => (
                <Zoom
                  in={true}
                  style={{ transitionDelay: `${index * 200}ms` }}
                  key={index}
                >
                  <div>
                    <CardDashboard
                      key={index}
                      duration={duration}
                      domain={domain}
                    />
                  </div>
                </Zoom>
              ))
            ) : titleCustom !== "" ? (
              <NoResult titleCustom={titleCustom} subTitleCustom={" "} />
            ) : null}
          </Grid>
        ) : (
          <Grid
            container={true}
            justifyContent="center"
            alignItems="center"
            className={classes.container}
          >
            {fakePlacehoderCardList.map((index: number) => (
              <CardDashboard
                key={index}
                domain={getDomainById(0)}
                duration={0}
                fake={true}
              />
            ))}
          </Grid>
        )}
      </Container>
    </Box>
  );
};

export default withRouter(Dashboard);
