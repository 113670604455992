import React, { useEffect, useRef, useState } from "react";
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from "react-router";
import { NavLink } from "react-router-dom";
import { AutoSizer } from "react-virtualized";
import Scrollbar from "react-scrollbars-custom";
import { useSnackbar } from "notistack";
// Material
import MenuIcon from "@material-ui/icons/Menu";
import {
  AppBar,
  Box,
  Button,
  CssBaseline,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Paper,
  Toolbar,
  Typography,
} from "@material-ui/core";
// Keycloak
import { useAuthentication } from "@dsk-lib/user";
// Style
import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
// Images
import logo from "../../assets/RF_Elearning_logo.png";
import arrowdown from "../../assets/arrow-down.svg";
// Components
import Menu from "../Menu/Menu";
import Home from "../Home/Home";
import NoMatch from "../NoMatch/NoMatch";
import BreadCrumb from "../../features/BreadCrumb/BreadCrumb";
import BaseSkills from "../BaseSkills/BaseSkills";
import Contact from "../Contact/Contact";
import Mention from "../Mention/Mention";
import News from "../News/News";
import Subject from "../Subject/Subject";
import Dashboard from "../Dashboard/Dashboard";
import ActivityLessonOrQuiz from "../ActivityLessonOrQuiz/ActivityLessonOrQuiz";
import Admin from "../Admin/Admin";
import NoAccess from "../NoAccess/NoAccess";
import Unauthorized from "../Unauthorized/Unauthorized";
// Services
import { disconnectUser, getUser } from "../../services/user.service";
import { PrivateRoute } from "../../features/PrivateRoute/PrivateRoute";
import { isIE } from "../../services/browser.service";
// Providers
import { SubjectProvider } from "../Subject/Subject.provider";
import { useScrollState } from "../../shared/Scroll.provider";
import { ActivityProvider } from "../../shared/Activity.provider";
// Hoc
import WithErrorHandler from "../../hoc/WithErrorHandler/WithErrorHandler";
// Models
import { User } from "../../models/user.model";
// Store
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../store";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

// Config
import { CONST } from "../../config/constant";
import {
  BookmarkStatus,
  BookmarkType,
  useQuizProgressPostMutation,
  useStarterQuizProgressLazyQuery,
  useTimePerDomainLazyQuery,
} from "../../graphql";

import OverlayLoader from "../../features/Loader/OverlayLoader";
import { setUserSuccess } from "../../store/user/actions";
import { getDomainById } from "../../helpers/domain.helper";

const drawerWidth = 300;

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      flexGrow: 1,
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    labelUsername: {
      cursor: "pointer",
    },
    username: {
      fontWeight: 600,
    },
    dropArrow: {
      height: 13,
      width: 13,
      cursor: "pointer",
    },
    paper: {
      position: "absolute",
      top: 55,
      width: 190,
      padding: 10,
      cursor: "pointer",
      display: "none",
    },
    hide: {
      display: "block",
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: "0 8px",
      ...theme.mixins.toolbar,
      justifyContent: "flex-end",
    },
    logoContainer: {
      flexGrow: 1,
      [theme.breakpoints.down("xs")]: {
        textAlign: "center",
      },
    },
    appBar: {
      zIndex: 2000,
      backgroundColor: "#ffffff",
      marginLeft: drawerWidth,
      width: `100%`,
      justifyContent: "center",
    },
    logo: {
      width: 200,
      [theme.breakpoints.down("xs")]: {
        width: "180px",
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    drawerPaper: {
      width: drawerWidth,
      overflowY: "hidden",
    },
    viewContainer: {
      flexGrow: 1,
      padding: theme.spacing(0),
    },
    scroller: {
      padding: theme.spacing(3),
    },
    content: {
      margin: "0 auto",
      paddingBottom: 20,
      minWidth: "90%",
      height: "calc(100vh  - 105px)",
    },
    toolbar: theme.mixins.toolbar,
    snackbarButton: {
      color: "white",
      borderColor: "white",
      margin: 5,
    },
  })
);

/**
 * Header username props
 */
interface HeaderUsernameProps {
  username: string;
  isSuperAdmin?: boolean;
}

/**
 * Header username component
 */
const HeaderUsername: React.FC<HeaderUsernameProps> = ({
  username,
  isSuperAdmin,
}: HeaderUsernameProps) => {
  /** Keycloak */
  const { keycloak } = useAuthentication();
  /** Classes */
  const classes = useStyles();
  /** deconnection open State */
  const [deconnexionOpen, setDeconnexionOpen] = useState(false);

  /** wrapperRef */
  const wrapperRef = useRef(null);
  /** wrapperRefImg */
  const wrapperRefImg = useRef(null);
  /** wrapperRefDeconnexion */
  const wrapperRefDeconnexion = useRef(null);
  /**
   * Hook that alerts clicks outside of the passed ref
   * @param ref
   */
  const useOutsideAlerter = (
    refLabel: any,
    refImg: any,
    refDeconnexion: any
  ) => {
    /**
     * Hide Deconnexion if clicked on outside of element
     */
    const handleClickOutside = (event: any) => {
      if (
        refLabel.current &&
        !refLabel.current.contains(event.target) &&
        refImg.current &&
        !refImg.current.contains(event.target) &&
        refDeconnexion.current &&
        !refDeconnexion.current.contains(event.target)
      ) {
        setDeconnexionOpen(false);
      }
    };

    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    });
  };

  useOutsideAlerter(wrapperRef, wrapperRefImg, wrapperRefDeconnexion);
  return (
    <Grid
      id="usernameHeader"
      container={true}
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
    >
      <Box
        p={1}
        className={classes.labelUsername}
        onClick={() => setDeconnexionOpen(!deconnexionOpen)}
      >
        <Typography
          ref={wrapperRef}
          variant={"subtitle2"}
          className={classes.username}
        >
          {username} <br />
          {isSuperAdmin && <span>Super Admin</span>}
        </Typography>
      </Box>
      <img
        ref={wrapperRefImg}
        src={arrowdown}
        alt="arrow-down-icon"
        onClick={() => setDeconnexionOpen(!deconnexionOpen)}
        className={classes.dropArrow}
      />
      <Paper
        ref={wrapperRefDeconnexion}
        className={`${classes.paper} ${deconnexionOpen ? classes.hide : null} `}
        onClick={() => disconnectUser(keycloak)}
      >
        <Typography variant={"body2"}>Déconnexion</Typography>
      </Paper>
    </Grid>
  );
};

/**
 * App component
 */
const App = ({ history }: RouteComponentProps) => {
  /** Classes */
  const classes = useStyles();
  /** Theme */
  const theme = useTheme();
  /** Mobile open State */
  const [mobileOpen, setMobileOpen] = useState(false);
  /** Loading state */
  const [loading, setLoading] = useState<boolean>(true);
  /** defaultPage */
  const [defaultPage, setDefaultPage] = useState<string>("");
  /** User state */
  const user: User | null = useSelector((state: StoreState) => state.user);
  /** Dispatch store */
  const dispatch = useDispatch<ThunkDispatch<StoreState, any, Action>>();
  /** User unauthorized */
  const [userUnauthorized, setUserUnauthorized] = useState<boolean>(false);
  /** GraphQL */

  /** Keycloak */
  const { authentication, keycloak, fetchWithCredentials } =
    useAuthentication();

  /** scrollPositionSelected */
  const { scrollPositionSelected } = useScrollState();
  /** use snackbar */
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  /** Browser is internet explorer <= 11 */
  const isInternetExplorer: boolean = isIE();

  const scrollBarRef = useRef<any>(null);

  const [updateBookmark1] = useQuizProgressPostMutation();

  const [updateBookmark2] = useQuizProgressPostMutation();

  const [updateBookmark3] = useQuizProgressPostMutation();

  const [updateBookmark4] = useQuizProgressPostMutation();

  const [updateBookmark5] = useQuizProgressPostMutation();

  const [updateBookmark6] = useQuizProgressPostMutation();

  const [updateBookmark7] = useQuizProgressPostMutation();

  const [getTimePerDomain, { data: timePerDomainData }] =
    useTimePerDomainLazyQuery({
      errorPolicy: "all",
      fetchPolicy: "network-only",
    });

  const [getQuizStarter1, { data: quizStarterProgressData1 }] =
    useStarterQuizProgressLazyQuery({
      errorPolicy: "all",
      fetchPolicy: "network-only",
    });
  const [getQuizStarter2, { data: quizStarterProgressData2 }] =
    useStarterQuizProgressLazyQuery({
      errorPolicy: "all",
      fetchPolicy: "network-only",
    });
  const [getQuizStarter3, { data: quizStarterProgressData3 }] =
    useStarterQuizProgressLazyQuery({
      errorPolicy: "all",
      fetchPolicy: "network-only",
    });
  const [getQuizStarter4, { data: quizStarterProgressData4 }] =
    useStarterQuizProgressLazyQuery({
      errorPolicy: "all",
      fetchPolicy: "network-only",
    });
  const [getQuizStarter5, { data: quizStarterProgressData5 }] =
    useStarterQuizProgressLazyQuery({
      errorPolicy: "all",
      fetchPolicy: "network-only",
    });
  const [getQuizStarter6, { data: quizStarterProgressData6 }] =
    useStarterQuizProgressLazyQuery({
      errorPolicy: "all",
      fetchPolicy: "network-only",
    });
  const [getQuizStarter7, { data: quizStarterProgressData7 }] =
    useStarterQuizProgressLazyQuery({
      errorPolicy: "all",
      fetchPolicy: "network-only",
    });

  useEffect(() => {
    if (authentication.isAuthenticated && user?._id) {
      console.log("Je suis auth");

      // recuperation de la progression de chaque starterQuiz
      getTimePerDomain({
        variables: {
          userId: user?._id,
          DOMAIN_ACCOUNTING: CONST.DOMAIN_ACCOUNTING,
          DOMAIN_TAX: CONST.DOMAIN_TAX,
          DOMAIN_SOCIAL: CONST.DOMAIN_SOCIAL,
          DOMAIN_PAYROLL: CONST.DOMAIN_PAYROLL,
          DOMAIN_BUSINESS: CONST.DOMAIN_BUSINESS,
          DOMAIN_HERITAGE: CONST.DOMAIN_HERITAGE,
          DOMAIN_OFFICE: CONST.DOMAIN_OFFICE,
        },
      });
    }
  }, [authentication.isAuthenticated, getTimePerDomain, user?._id]);

  useEffect(() => {
    if (timePerDomainData) {
      console.log("call les progress");
      getQuizStarter1({ variables: { idOrPath: CONST.DOMAIN_ACCOUNTING } });
      getQuizStarter2({ variables: { idOrPath: CONST.DOMAIN_TAX } });
      getQuizStarter3({ variables: { idOrPath: CONST.DOMAIN_SOCIAL } });
      getQuizStarter4({ variables: { idOrPath: CONST.DOMAIN_PAYROLL } });
      getQuizStarter5({ variables: { idOrPath: CONST.DOMAIN_BUSINESS } });
      getQuizStarter6({ variables: { idOrPath: CONST.DOMAIN_HERITAGE } });
      getQuizStarter7({ variables: { idOrPath: CONST.DOMAIN_OFFICE } });
    }
  }, [
    getQuizStarter1,
    getQuizStarter2,
    getQuizStarter3,
    getQuizStarter4,
    getQuizStarter5,
    getQuizStarter6,
    getQuizStarter7,
    timePerDomainData,
  ]);

  //resultat du quizProgress
  useEffect(() => {
    if (
      quizStarterProgressData1?.category?.quizzes[0]?._id &&
      timePerDomainData?.DOMAIN_ACCOUNTING?.duration
    ) {
      console.log(
        "COMPTA : id :",
        quizStarterProgressData1?.category?.quizzes[0]._id,
        " progression: ",
        quizStarterProgressData1?.category?.quizzes[0]?.bookmark
      );
      if (
        quizStarterProgressData1?.category?.quizzes[0]?.bookmark?.every(
          (bookmark) => bookmark.status !== BookmarkStatus.Done
        )
      ) {
        console.log(
          "COMPTA:  pas done  on continue le traitement , verification du temps sur le domaine"
        );
        if (timePerDomainData?.DOMAIN_ACCOUNTING.duration === 0) {
          console.log("pas de temps sur le domaine");
        } else {
          console.log(
            "COMPTA:  ON UPDATE BOOKMARK temps :",
            timePerDomainData?.DOMAIN_ACCOUNTING.duration
          );
          updateBookmark1({
            variables: {
              id: quizStarterProgressData1.category.quizzes[0]._id,
              type: BookmarkType.MockExam,
              status: BookmarkStatus.Done,
              score: quizStarterProgressData1.category.quizzes[0].successScore,
            },
          });
        }
      } else {
        console.log("COMPTA: : Le starterQuiz est DONE");
      }
    }
  }, [
    quizStarterProgressData1,
    timePerDomainData?.DOMAIN_ACCOUNTING?.duration,
    updateBookmark1,
  ]);

  //resultat du quizProgress
  useEffect(() => {
    if (
      quizStarterProgressData2?.category?.quizzes[0]?._id &&
      timePerDomainData?.DOMAIN_TAX?.duration
    ) {
      console.log(
        "FISCALITE : id :",
        quizStarterProgressData2?.category?.quizzes[0]._id,
        " progression: ",
        quizStarterProgressData2?.category?.quizzes[0]?.bookmark
      );
      if (
        quizStarterProgressData2?.category?.quizzes[0]?.bookmark?.every(
          (bookmark) => bookmark.status !== BookmarkStatus.Done
        )
      ) {
        console.log(
          "FISCALITE:  pas done  on continue le traitement , verification du temps sur le domaine"
        );
        if (timePerDomainData?.DOMAIN_TAX.duration === 0) {
          console.log("pas de temps sur le domaine");
        } else {
          console.log(
            "FISCALITE:  ON UPDATE BOOKMARK temps :",
            timePerDomainData?.DOMAIN_TAX.duration
          );
          updateBookmark2({
            variables: {
              id: quizStarterProgressData2.category.quizzes[0]._id,
              type: BookmarkType.MockExam,
              status: BookmarkStatus.Done,
              score: quizStarterProgressData2.category.quizzes[0].successScore,
            },
          });
        }
      } else {
        console.log("FISCALITE: : Le starterQuiz est DONE");
      }
    }
  }, [
    quizStarterProgressData2,
    timePerDomainData?.DOMAIN_TAX?.duration,
    updateBookmark2,
  ]);

  //resultat du quizProgress
  useEffect(() => {
    if (
      quizStarterProgressData3?.category?.quizzes[0]?._id &&
      timePerDomainData?.DOMAIN_SOCIAL?.duration
    ) {
      console.log(
        "DROIT DU TRAVAIL : id :",
        quizStarterProgressData3?.category?.quizzes[0]._id,
        " progression: ",
        quizStarterProgressData3?.category?.quizzes[0]?.bookmark
      );
      if (
        quizStarterProgressData3?.category?.quizzes[0]?.bookmark?.every(
          (bookmark) => bookmark.status !== BookmarkStatus.Done
        )
      ) {
        console.log(
          "DROIT DU TRAVAIL :  pas done  on continue le traitement , verification du temps sur le domaine"
        );
        if (timePerDomainData?.DOMAIN_SOCIAL.duration === 0) {
          console.log("pas de temps sur le domaine");
        } else {
          console.log(
            "DROIT DU TRAVAIL :  ON UPDATE BOOKMARK temps :",
            timePerDomainData?.DOMAIN_SOCIAL.duration
          );
          updateBookmark3({
            variables: {
              id: quizStarterProgressData3.category.quizzes[0]._id,
              type: BookmarkType.MockExam,
              status: BookmarkStatus.Done,
              score: quizStarterProgressData3.category.quizzes[0].successScore,
            },
          });
        }
      } else {
        console.log("DROIT DU TRAVAIL : : Le starterQuiz est DONE");
      }
    }
  }, [
    quizStarterProgressData3,
    timePerDomainData?.DOMAIN_SOCIAL?.duration,
    updateBookmark3,
  ]);

  //resultat du quizProgress
  useEffect(() => {
    if (
      quizStarterProgressData4?.category?.quizzes[0]?._id &&
      timePerDomainData?.DOMAIN_PAYROLL?.duration
    ) {
      console.log(
        "GESTION DE LA PAYE : id :",
        quizStarterProgressData4?.category?.quizzes[0]._id,
        " progression: ",
        quizStarterProgressData4?.category?.quizzes[0]?.bookmark
      );
      if (
        quizStarterProgressData4?.category?.quizzes[0]?.bookmark?.every(
          (bookmark) => bookmark.status !== BookmarkStatus.Done
        )
      ) {
        console.log(
          "GESTION DE LA PAYE:  pas done  on continue le traitement , verification du temps sur le domaine"
        );
        if (timePerDomainData?.DOMAIN_PAYROLL.duration === 0) {
          console.log("pas de temps sur le domaine");
        } else {
          console.log(
            "GESTION DE LA PAYE:  ON UPDATE BOOKMARK temps :",
            timePerDomainData?.DOMAIN_PAYROLL.duration
          );
          updateBookmark4({
            variables: {
              id: quizStarterProgressData4.category.quizzes[0]._id,
              type: BookmarkType.MockExam,
              status: BookmarkStatus.Done,
              score: quizStarterProgressData4.category.quizzes[0].successScore,
            },
          });
        }
      } else {
        console.log("GESTION DE LA PAYE: : Le starterQuiz est DONE");
      }
    }
  }, [
    quizStarterProgressData4,
    timePerDomainData?.DOMAIN_PAYROLL?.duration,
    updateBookmark4,
  ]);

  //resultat du quizProgress
  useEffect(() => {
    if (
      quizStarterProgressData5?.category?.quizzes[0]?._id &&
      timePerDomainData?.DOMAIN_BUSINESS?.duration
    ) {
      console.log(
        "DROIT DES AFFAIRES : id :",
        quizStarterProgressData5?.category?.quizzes[0]._id,
        " progression: ",
        quizStarterProgressData5?.category?.quizzes[0]?.bookmark
      );
      if (
        quizStarterProgressData5?.category?.quizzes[0]?.bookmark?.every(
          (bookmark) => bookmark.status !== BookmarkStatus.Done
        )
      ) {
        console.log(
          "DROIT DES AFFAIRES:  pas done  on continue le traitement , verification du temps sur le domaine"
        );
        if (timePerDomainData?.DOMAIN_BUSINESS.duration === 0) {
          console.log("pas de temps sur le domaine");
        } else {
          console.log(
            "DROIT DES AFFAIRES:  ON UPDATE BOOKMARK temps :",
            timePerDomainData?.DOMAIN_BUSINESS.duration
          );
          updateBookmark5({
            variables: {
              id: quizStarterProgressData5.category.quizzes[0]._id,
              type: BookmarkType.MockExam,
              status: BookmarkStatus.Done,
              score: quizStarterProgressData5.category.quizzes[0].successScore,
            },
          });
        }
      } else {
        console.log("DROIT DES AFFAIRES: : Le starterQuiz est DONE");
      }
    }
  }, [
    quizStarterProgressData5,
    timePerDomainData?.DOMAIN_BUSINESS?.duration,
    updateBookmark5,
  ]);

  //resultat du quizProgress
  useEffect(() => {
    if (
      quizStarterProgressData6?.category?.quizzes[0]?._id &&
      timePerDomainData?.DOMAIN_HERITAGE?.duration
    ) {
      console.log(
        "GESTION DU PATRIMOINE : id :",
        quizStarterProgressData6?.category?.quizzes[0]._id,
        " progression: ",
        quizStarterProgressData6?.category?.quizzes[0]?.bookmark
      );
      if (
        quizStarterProgressData6?.category?.quizzes[0]?.bookmark?.every(
          (bookmark) => bookmark.status !== BookmarkStatus.Done
        )
      ) {
        console.log(
          "GESTION DU PATRIMOINE:  pas done  on continue le traitement , verification du temps sur le domaine"
        );
        if (timePerDomainData?.DOMAIN_HERITAGE.duration === 0) {
          console.log("pas de temps sur le domaine");
        } else {
          console.log(
            "GESTION DU PATRIMOINE:  ON UPDATE BOOKMARK temps :",
            timePerDomainData?.DOMAIN_HERITAGE.duration
          );
          updateBookmark6({
            variables: {
              id: quizStarterProgressData6.category.quizzes[0]._id,
              type: BookmarkType.MockExam,
              status: BookmarkStatus.Done,
              score: quizStarterProgressData6.category.quizzes[0].successScore,
            },
          });
        }
      } else {
        console.log("GESTION DU PATRIMOINE: : Le starterQuiz est DONE");
      }
    }
  }, [
    quizStarterProgressData6,
    timePerDomainData?.DOMAIN_HERITAGE?.duration,
    updateBookmark6,
  ]);

  //resultat du quizProgress
  useEffect(() => {
    if (
      quizStarterProgressData7?.category?.quizzes[0]?._id &&
      timePerDomainData?.DOMAIN_OFFICE?.duration
    ) {
      console.log(
        "PERFORMANCE DU CABINET : id :",
        quizStarterProgressData7?.category?.quizzes[0]._id,
        " progression: ",
        quizStarterProgressData7?.category?.quizzes[0]?.bookmark
      );
      if (
        quizStarterProgressData7?.category?.quizzes[0]?.bookmark?.every(
          (bookmark) => bookmark.status !== BookmarkStatus.Done
        )
      ) {
        console.log(
          "PERFORMANCE DU CABINET:  pas done  on continue le traitement , verification du temps sur le domaine"
        );
        if (timePerDomainData?.DOMAIN_OFFICE.duration === 0) {
          console.log("pas de temps sur le domaine");
        } else {
          console.log(
            "PERFORMANCE DU CABINET:  ON UPDATE BOOKMARK temps :",
            timePerDomainData?.DOMAIN_OFFICE.duration
          );
          updateBookmark7({
            variables: {
              id: quizStarterProgressData7.category.quizzes[0]._id,
              type: BookmarkType.MockExam,
              status: BookmarkStatus.Done,
              score: quizStarterProgressData7.category.quizzes[0].successScore,
            },
          });
        }
      } else {
        console.log("PERFORMANCE DU CABINET: : Le starterQuiz est DONE");
      }
    }
  }, [
    quizStarterProgressData7,
    timePerDomainData?.DOMAIN_OFFICE?.duration,
    updateBookmark7,
  ]);

  /** Use effect user */
  useEffect(() => {
    if (user) {
      let _defaultPage = "/mon-suivi";
      let redirect = true;

      if (user.subscription.fullDomainsAccessEl.length > 1) {
        _defaultPage = "/a-la-une";
        redirect = false;
      } else if (user.subscription.fullDomainsAccessEl.length === 1) {
        const domain = getDomainById(user.subscription.fullDomainsAccessEl[0]);
        _defaultPage = `/categorie/${domain.slug}/actualites`;
        redirect = false;
      } else if (user.subscription.domainsAccessEl.length > 1) {
        const domain = getDomainById(user.subscription.domainsAccessEl[0]);
        _defaultPage = `/categorie/${domain.slug}/competences`;
        redirect = false;
      } else if (user.isAdmin) {
        _defaultPage = "/admin";
      }

      setDefaultPage(_defaultPage);
      if (redirect) {
        history.push(_defaultPage);
      }
    }
  }, [history, keycloak, user]);

  /** Use effect */
  useEffect(() => {
    if (isInternetExplorer) {
      // customized actions
      const action = () => (
        <div
          style={{
            margin: "auto",
          }}
        >
          <Button
            className={classes.snackbarButton}
            variant="outlined"
            onClick={() => {
              window.open(
                "https://www.google.com/intl/fr_fr/chrome/",
                "_blank"
              );
            }}
          >
            Télécharger Chrome
          </Button>
          <Button
            className={classes.snackbarButton}
            variant="outlined"
            onClick={() => {
              window.open(
                "https://www.microsoft.com/fr-fr/windows/microsoft-edge",
                "_blank"
              );
            }}
          >
            Télécharger Edge
          </Button>
          <Button
            className={classes.snackbarButton}
            variant="outlined"
            onClick={() => {
              window.open("https://www.mozilla.org/fr/firefox/", "_blank");
            }}
          >
            Télécharger Firefox
          </Button>
          <Button
            className={classes.snackbarButton}
            onClick={() => {
              closeSnackbar();
            }}
          >
            Ignorer
          </Button>
        </div>
      );

      enqueueSnackbar("Attention, vous utilisez un navigateur obsolète.", {
        variant: "warning",
        persist: true,
        action,
      });
    }
  }, [
    classes.snackbarButton,
    closeSnackbar,
    enqueueSnackbar,
    isInternetExplorer,
  ]);

  /** Use effect */
  useEffect(() => {
    // Todo: Remove this code line when @dsk-lib/user will be updated
    keycloak &&
      (keycloak.onAuthRefreshError = () => {
        keycloak.login();
      });

    if (!authentication.isPending && !authentication.isAuthenticated) {
      const referrer = localStorage.getItem("referrer") || "";
      if (
        (!referrer.includes(CONST.PAGE_PRODUCTLIST) || !referrer) &&
        !process.env.REACT_APP_LOCK_PORTAL_REDIRECTION
      ) {
        window.location.replace(CONST.PAGE_PRODUCTLIST);
        return;
      }
      localStorage.removeItem("referrer");
      keycloak && keycloak.login();
    } else if (!authentication.isPending && authentication.isAuthenticated) {
      const fetchUser = async () => {
        try {
          const user = await getUser(fetchWithCredentials);

          if (user) {
            dispatch(setUserSuccess(user));
            setLoading(false);
          } else {
            setUserUnauthorized(true);
            setLoading(false);
          }
        } catch (e) {
          setLoading(false);
        }
      };

      fetchUser();
    }
  }, [
    authentication.isAuthenticated,
    authentication.isPending,
    dispatch,
    fetchWithCredentials,
    keycloak,
  ]);

  /**
   * Handle drawer toggle
   */
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  if (
    document.getElementsByClassName(
      "ScrollbarsCustom-Thumb ScrollbarsCustom-ThumbY"
    )[0]
  ) {
    // tslint:disable-next-line: max-line-length
    (
      document.getElementsByClassName(
        "ScrollbarsCustom-Thumb ScrollbarsCustom-ThumbY"
      )[0] as HTMLElement
    ).style.backgroundColor = "#CCCCCC";
  }
  if (
    document.getElementsByClassName(
      "ScrollbarsCustom-Track ScrollbarsCustom-TrackY"
    )[0]
  ) {
    // tslint:disable-next-line: max-line-length
    (
      document.getElementsByClassName(
        "ScrollbarsCustom-Track ScrollbarsCustom-TrackY"
      )[0] as HTMLElement
    ).style.width = "8px";
    (
      document.getElementsByClassName(
        "ScrollbarsCustom-Track ScrollbarsCustom-TrackY"
      )[0] as HTMLElement
    ).style.top = "0px";
  }

  useEffect(() => {
    if (scrollBarRef?.current) {
      scrollBarRef.current.scrollTo(undefined, scrollPositionSelected);
    }
  }, [scrollPositionSelected]);

  return (
    <div className={classes.root}>
      {loading ? (
        <OverlayLoader />
      ) : (
        <React.Fragment>
          <CssBaseline />
          <AppBar position="fixed" className={classes.appBar} color="default">
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
              <div className={classes.logoContainer}>
                <NavLink to={defaultPage}>
                  <img src={logo} className={classes.logo} alt="rf-elearning" />
                </NavLink>
              </div>
              <Hidden smUp={true}>
                <Box m={3} />
              </Hidden>
              <Hidden xsDown={true}>
                <HeaderUsername
                  username={`${user?.firstName} ${user?.lastName}`}
                  isSuperAdmin={user?.isSuperAdmin}
                />
              </Hidden>
            </Toolbar>
          </AppBar>
          {userUnauthorized ? (
            <Unauthorized />
          ) : (
            <WithErrorHandler setUserUnauthorized={setUserUnauthorized}>
              <nav className={classes.drawer} aria-label="navigation">
                <Hidden smUp={true} implementation="css">
                  <Drawer
                    variant="temporary"
                    anchor={theme.direction === "rtl" ? "right" : "left"}
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    classes={{
                      paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                      keepMounted: true,
                    }}
                  >
                    <Menu
                      isMobile={true}
                      username={`${user?.firstName} ${user?.lastName}`}
                      onClose={handleDrawerToggle}
                    />
                  </Drawer>
                </Hidden>
                <Hidden xsDown={true} implementation="css">
                  <Drawer
                    variant="permanent"
                    classes={{
                      paper: classes.drawerPaper,
                    }}
                    open={true}
                  >
                    <Menu onClose={handleDrawerToggle} />
                  </Drawer>
                </Hidden>
              </nav>
              <main className={classes.viewContainer}>
                <div className={classes.drawerHeader} />
                {user ? (
                  <React.Fragment>
                    <BreadCrumb />
                    <div className={classes.content}>
                      <AutoSizer>
                        {({ width, height }) => {
                          return (
                            <ActivityProvider>
                              <SubjectProvider>
                                <Scrollbar
                                  ref={scrollBarRef}
                                  style={{ width, height }}
                                  noScrollX={true}
                                >
                                  <Box
                                    marginBottom={15}
                                    marginTop={5}
                                    marginLeft={2}
                                    marginRight={2}
                                  >
                                    <Switch>
                                      <Redirect
                                        from="/"
                                        to={defaultPage}
                                        exact={true}
                                      />
                                      <Route
                                        path="/contact"
                                        component={Contact}
                                        exact={true}
                                      />
                                      <Route
                                        path="/mon-suivi"
                                        component={Dashboard}
                                        exact={true}
                                      />
                                      <Route
                                        path="/mention"
                                        component={Mention}
                                        exact={true}
                                      />
                                      <PrivateRoute
                                        path="/admin"
                                        component={Admin}
                                        exact={true}
                                      />
                                      <Route
                                        path="/noaccess"
                                        component={NoAccess}
                                        exact={true}
                                      />
                                      <Route
                                        path="/a-la-une/dossier-actualites/:subjectId/:type/:activityId"
                                        component={ActivityLessonOrQuiz}
                                      />
                                      <Route
                                        path="/a-la-une/dossier-actualites/:subjectId"
                                        component={Subject}
                                      />
                                      <Route
                                        path="/a-la-une/:type/:activityId"
                                        component={ActivityLessonOrQuiz}
                                      />
                                      <Route
                                        path="/a-la-une"
                                        component={Home}
                                      />
                                      <Route
                                        path="/:domainSlug/competences/:subjectId/:type/:activityId"
                                        component={ActivityLessonOrQuiz}
                                      />
                                      <Route
                                        path="/:domainSlug/competences/:subjectId"
                                        component={Subject}
                                      />
                                      <Route
                                        path="/:domainSlug/competences"
                                        component={BaseSkills}
                                      />
                                      <Route
                                        path="/:domainSlug/positionnement/:activityId"
                                        component={ActivityLessonOrQuiz}
                                      />
                                      <Route
                                        path="/:domainSlug/actualites/dossier-actualites/:subjectId/:type/:activityId"
                                        component={ActivityLessonOrQuiz}
                                      />
                                      <Route
                                        path="/:domainSlug/actualites/dossier-actualites/:subjectId"
                                        component={Subject}
                                      />
                                      <Route
                                        path="/:domainSlug/actualites/:type/:activityId"
                                        component={ActivityLessonOrQuiz}
                                      />
                                      <Route
                                        path="/:domainSlug/actualites"
                                        component={News}
                                      />
                                      <Route component={NoMatch} />
                                    </Switch>
                                  </Box>
                                </Scrollbar>
                              </SubjectProvider>
                            </ActivityProvider>
                          );
                        }}
                      </AutoSizer>
                    </div>
                  </React.Fragment>
                ) : null}
              </main>
            </WithErrorHandler>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default withRouter(App);
