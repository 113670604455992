// React
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React, { useMemo } from "react";

// Material - ui
import { Grid, Typography } from "@material-ui/core";

// Helpers
import { isPlurial } from "../../helpers/card.helper";
import { ProgressCategoryQuery } from "../../graphql";
import { secondsToMinutes } from "../../helpers/date-fomatter.helper";

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    timeList: {
      flexDirection: "row",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    timeLabel: {
      fontSize: "12px",
      lineHeight: 1.5,
      letterSpacing: "0.87px",
      color: "#65747b",
    },
    timeValue: {
      display: "inline-block",
      fontSize: "12px",
      fontWeight: 600,
      letterSpacing: "-0.3px",
      color: "#263c46",
    },
    activityValue: {
      fontSize: "12px",
      letterSpacing: "0.86px",
      color: "#65747b",
    },
    vrBorderGrid: {
      padding: "0 10px",
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    vrBorder: {
      borderLeft: "solid 1px rgba(0, 0, 0, 0.2)",
      width: "1px",
      height: "13px",
    },
  })
);

/**
 * TimeIndicator props
 */
interface TimeIndicator2Props {
  isCategory: boolean;
  color?: string;
  confirmedTime?: string;
  estimedTime?: string;
  nbActivities?: number;
  isQuiz?: boolean;
  questionCount?: number;
  validatedQuestionCount?: number;
  category?: ProgressCategoryQuery["category"];
}

/**
 * Time indicator
 */
const TimeIndicator2 = ({
  isCategory,
  color,
  confirmedTime,
  estimedTime,
  nbActivities,
  isQuiz,
  questionCount,
  validatedQuestionCount,
  category,
}: TimeIndicator2Props) => {
  /** Classes */
  const classes = useStyles();

  const totalDuration = useMemo(() => {
    const calculateDuration = (node) => {
      if (!node) return 0;

      // Somme des durées des lessons et quizzes du noeud courant
      const lessonsDuration =
        node.lessons?.reduce(
          (sum, lesson) => sum + (lesson.elapsedTime?.duration || 0),
          0
        ) || 0;
      const quizzesDuration =
        node.quizzes?.reduce(
          (sum, quiz) => sum + (quiz.elapsedTime?.duration || 0),
          0
        ) || 0;

      // Somme des durées des enfants récursivement
      const childrenDuration =
        node.childrenDataV2?.reduce(
          (sum, child) => sum + calculateDuration(child),
          0
        ) || 0;

      return lessonsDuration + quizzesDuration + childrenDuration;
    };

    return calculateDuration(category);
  }, [category]); // Dépendance : recalcul seulement si `category` change

  return (
    <Grid
      className={classes.timeList}
      container={true}
      item={true}
      justifyContent="flex-start"
    >
      {isCategory && nbActivities !== undefined ? (
        <Grid item={true}>
          <Typography
            component="span"
            className={classes.activityValue}
            gutterBottom={true}
          >
            {nbActivities} {isPlurial(nbActivities)}
          </Typography>
        </Grid>
      ) : (
        <React.Fragment>
          <Grid item={true}>
            <Typography
              component="span"
              className={classes.timeLabel}
              color="textSecondary"
              gutterBottom={true}
              noWrap={true}
            >
              Temps de l’activité&nbsp;
            </Typography>
            <Typography
              component="span"
              className={classes.timeValue}
              gutterBottom={true}
            >
              {estimedTime}
            </Typography>
          </Grid>
          {/* {isQuiz ? (
            <React.Fragment>
              <Grid item={true} className={classes.vrBorderGrid}>
                <div className={classes.vrBorder} />
              </Grid>
              <Grid item={true}>
                <Typography
                  component="span"
                  className={classes.timeLabel}
                  color="textSecondary"
                  gutterBottom={true}
                  noWrap={true}
                >
                  Questions réalisées&nbsp;
                </Typography>
                <Typography
                  component="span"
                  className={classes.timeValue}
                  gutterBottom={true}
                >
                  {validatedQuestionCount}/{questionCount}
                </Typography>
              </Grid>
            </React.Fragment>
          ) : null} */}
        </React.Fragment>
      )}
      {
        //! TODO faire l'appel pour recuperer les temps realisées aggregé des activités et de faire le système de De tag  NEW OU UPDATE ( basé sur le tag )
        category ? (
          <>
            <Grid item={true} className={classes.vrBorderGrid}>
              <div className={classes.vrBorder} />
            </Grid>
            <Grid item={true}>
              <Typography
                component="span"
                className={classes.timeLabel}
                color="textSecondary"
                gutterBottom={true}
                noWrap={true}
              >
                Temps de formation réalisé&nbsp;
              </Typography>
              <Typography
                component="span"
                className={classes.timeValue}
                gutterBottom={true}
                style={{ color: color ?? "fffff" }}
              >
                {secondsToMinutes(totalDuration)} min
              </Typography>
            </Grid>
          </>
        ) : null
      }
      {confirmedTime ? (
        <>
          <Grid item={true} className={classes.vrBorderGrid}>
            <div className={classes.vrBorder} />
          </Grid>
          <Grid item={true}>
            <Typography
              component="span"
              className={classes.timeLabel}
              color="textSecondary"
              gutterBottom={true}
              noWrap={true}
            >
              Temps de formation réalisé&nbsp;
            </Typography>
            <Typography
              component="span"
              className={classes.timeValue}
              gutterBottom={true}
              style={{ color: color ?? "fffff" }}
            >
              {confirmedTime}
            </Typography>
          </Grid>
        </>
      ) : null}
    </Grid>
  );
};

export default React.memo(TimeIndicator2);
