import React, { useEffect, useMemo } from "react";
import {
  Card,
  CardContent,
  Collapse,
  Grid,
  List,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { DOMAINS } from "../../models/domain.model";

// Components
import TimeIndicator2 from "../../features/TimeIndicator/TimeIndicator2";
import { yellow } from "@material-ui/core/colors";
import {
  Status,
  ThemeCardFragment,
  useBaseSkill2LazyQuery,
  useProgressCategoryLazyQuery,
} from "../../graphql";
import BaseSkillsItem from "../BaseSkillsItem/BaseSkillsItem";
import hexToRgba from "hex-to-rgba";

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardCategory: {
      width: "100%",
    },
    cardSubCategory: {
      width: "100%",
      zIndex: 10,
    },
    cardSubCategoryCollapse: {
      paddingLeft: 20,
      paddingBottom: 8,
      width: "100%",
    },
    cardSubject: {
      width: "100%",
    },
    content: {
      padding: 14,
      "&:last-child": {
        paddingBottom: 14,
      },
    },
    title: {
      minHeight: 22,
      fontWeight: 600,
      letterSpacing: 1.07,
      fontSize: 16,
      justifyContent: "center",
    },
    subtitle: {
      letterSpacing: 0.86,
      fontSize: 12,
      justifyContent: "center",
    },
    container: {
      width: "100%",
    },
    circleCategory: {
      borderRadius: "50%",
    },
    dot: {
      margin: 1,
      marginRight: 2,
      width: 8,
      height: 8,
      borderRadius: "50%",
      display: "inline-block",
      backgroundColor: theme.palette.primary.main,
    },
    dotUpdate: {
      backgroundColor: yellow[600],
    },
    dotNew: {
      backgroundColor: theme.palette.primary.main,
    },
    isExpandLess: {
      backgroundColor: "#e5e5e5",
    },
    card: {
      paddindTop: 10,
    },
  })
);

/**
 * CardCategory props
 */
interface CardCategoryProps {
  category: ThemeCardFragment;
  isExpandLess: boolean;
  domainSlug: string;
  locked?: boolean;
  onLockedClick?: () => void;
}

/**
 * Card category
 */
const CardCategory = ({
  category,
  isExpandLess,
  domainSlug,
  locked = false,
  onLockedClick,
}: CardCategoryProps) => {
  /** classes */
  const classes = useStyles();
  const [getBaseSkill, { data: themeData }] = useBaseSkill2LazyQuery({
    errorPolicy: "ignore",
    fetchPolicy: "cache-and-network",
  });

  const [getProgressCategory, { data: progressData }] =
    useProgressCategoryLazyQuery({
      errorPolicy: "ignore",
      fetchPolicy: "cache-and-network",
    });

  const colorDomain = useMemo(() => {
    return DOMAINS.find((currentDomain) => currentDomain.slug === domainSlug)
      .color;
  }, [domainSlug]);

  /** If state of category is present change size */
  //! TODO remettre pour gerer l'etat de la categorie ( UPDATE , NEW)
  //const sizeCategory = category.state ? 9 : 11;
  const [open, setOpen] = React.useState<boolean>(false);

  useEffect(() => {
    if(category._id){
      getBaseSkill({
        variables: {
          idOrPath: `${category._id}`,
        },
      });
    }
  }, [category._id, getBaseSkill]);

  useEffect(() => {
    if (category._id && open && themeData?.category) {
      getProgressCategory({
        variables: {
          idOrPath: `${category._id}`,
        },
      });
    }
  }, [category._id, getProgressCategory, open, themeData?.category]);

  return (
    <div id="cardCategory" className={classes.container}>
      <Grid
        container={true}
        alignItems={"center"}
        wrap={"nowrap"}
        onClick={() => setOpen((opened) => !opened)}
      >
        <Grid item={true} xs={true}>
          <Card
            className={`${isExpandLess ? classes.isExpandLess : classes.card}`}
          >
            <CardContent className={classes.content}>
              <Grid container={true}>
                <Grid item={true} xs={11}>
                  <Grid
                    container={true}
                    direction="row"
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item={true} xs="auto">
                      <Typography
                        className={classes.title}
                        color="textPrimary"
                        noWrap={false}
                        style={{ color: colorDomain }}
                      >
                        {category.status === Status.Draft
                          ? "[BROUILLON]"
                          : null}{" "}
                        {category.title}
                      </Typography>
                    </Grid>

                    <TimeIndicator2
                      isCategory={true}
                      color={colorDomain}
                      category={progressData?.category}
                      nbActivities={
                        category.recursiveCounts?.lessons +
                        category.recursiveCounts?.quizzes
                      }
                    />
                  </Grid>

                  {/* <Hidden mdUp={true}>
                    <Grid container={true} direction="column">
                      <Typography
                        className={classes.title}
                        color="textPrimary"
                        gutterBottom={true}
                        style={{ color: getDomainColor(domain) }}
                      >
                        {category.status === Status.Draft
                          ? "[BROUILLON]"
                          : null}{" "}
                        {category.title}
                      </Typography>
                      {category.state ? (
                        <Typography
                          color="textSecondary"
                          gutterBottom={true}
                          noWrap={true}
                        >
                          <span
                            className={`${classes.dot} ${
                              category.state === ActivityState.UPDATE
                                ? classes.dotUpdate
                                : classes.dotNew
                            }`}
                          />
                          <span>{category.state}</span>
                        </Typography>
                      ) : null}
                      <TimeIndicator
                        isCategory={true}
                        domain={domain}
                        confirmedTime={`${secondsToMinutes(
                          category.confirmedTime
                        )} min`}
                        nbActivities={category.nbActivities}
                      />
                    </Grid>
                  </Hidden> */}
                </Grid>
                {/* {category.state ? (
                  <Grid item={true} xs={2}>
                    <Grid container={true} justifyContent="center">
                      <Hidden smDown={true} implementation="css">
                        <Typography color="textSecondary" noWrap={true}>
                          <span
                            className={`${classes.dot} ${
                              category.state === ActivityState.UPDATE
                                ? classes.dotUpdate
                                : classes.dotNew
                            }`}
                          />
                          {category.state}
                        </Typography>
                      </Hidden>
                    </Grid>
                  </Grid>
                ) : null} */}
                <Grid item={true} xs={1}>
                  <Grid
                    container={true}
                    alignItems="center"
                    justifyContent="center"
                  >
                    {isExpandLess ? (
                      <ExpandLess
                        className={classes.circleCategory}
                        style={{
                          color: colorDomain,
                          backgroundColor: hexToRgba(colorDomain, 0.2),
                        }}
                      />
                    ) : (
                      <ExpandMore
                        className={classes.circleCategory}
                        style={{
                          color: colorDomain,
                          backgroundColor: hexToRgba(colorDomain, 0.2),
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {open && themeData?.category ? (
        <Collapse
          key={category._id}
          className={classes.cardSubCategoryCollapse}
          in={open}
          timeout="auto"
          unmountOnExit={true}
        >
          <List disablePadding={true} component="div">
            {themeData.category?.childrenDataV2.map(
              (category: ThemeCardFragment) => (
                <BaseSkillsItem
                  key={category._id}
                  category={category}
                  domainSlug={domainSlug}
                  locked={locked}
                  onLockedClick={onLockedClick}
                />
              )
            )}
          </List>
        </Collapse>
      ) : null}
    </div>
  );
};

export default React.memo(CardCategory);
