/**
 * Is plurial
 * @param nbActivity
 */
export const isPlurial = (nbActivity: number): string => {
  return nbActivity > 1 ? "activités" : "activité";
};

/**
 * Clean accent label
 * @param label
 */
export const cleanAccentLabel = (label: string): string => {
  switch (label) {
    case "VIDEO":
      return "Vidéo";
    case "MEMO":
      return "Mémo";
    case "DECRYPTAGE":
      return "Décryptage";
    case "A_RETENIR":
      return "À retenir";
    case "ACTU_METIER":
      return "Actu métier";
    case "QCM":
      return "QCM";
    case "L_ILLUSTRE":
      return "L'illustré";
    default:
      return titleCase(label!);
  }
};

/**
 * titleCase modify string HELLO WORLD to Hello World
 * @param str
 */
export const titleCase = (str: string): string => {
  return str
    .replace("_", " ")
    .split(" ")
    .map((item) => item.charAt(0).toUpperCase() + item.slice(1).toLowerCase())
    .join(" ");
};
