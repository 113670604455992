// React
import React, { useEffect } from "react";
// Material
import { Container } from "@material-ui/core";
// Component
import Viewers from "../../component/Viewer/Viewers";
import YouTubePlayer from "../../features/Video/YouTubePlayer";
// Styles
import "./Lessons.scss";
// Models
import { Lesson } from "../../models/Activity.model";
import { getYoutubeId } from "../../services/activity.service";
// Helpers
import { validURL } from "../../helpers/url.helper";
import { CONST } from "../../config/constant";

import { getUserId } from "../../services/user.service";
import { useLessonTimePostMutation } from "../../graphql";

/**
 * Lesson props
 */
interface LessonProps {
  playTimer: (play: boolean) => void;
  lesson: Lesson;
  fontSize: number;
}

/**
 * Lesson component
 */
const Lessons = ({ playTimer, lesson, fontSize }: LessonProps) => {
  /** Is video */
  const isVideo: boolean = lesson.nature === "VIDEO";

  /**
   * Handle play timer
   * @param play
   */
  const handlePlayTimer = (play: boolean): void => {
    playTimer(play);
    //   connect(play);
  };

  const getTimestamp = (date: Date) => {
    return Math.floor(date.getTime() / 1000);
  };

  const [saveLessonTime] = useLessonTimePostMutation();

  /** Use effect to auto play or not timer */
  useEffect(() => {
    playTimer(!isVideo);
  }, [isVideo, playTimer]);

  useEffect(() => {
    const startTimestamp = getTimestamp(new Date());

    return () => {
      const endTimestamp = getTimestamp(new Date());
      const duration = endTimestamp - startTimestamp;
      if (lesson.estimatedTime && duration) {
        const durationWithBuffer = duration + lesson.confirmedTime;
        if (durationWithBuffer <= lesson.estimatedTime) {
          console.log("test1");

          saveLessonTime({
            variables: {
              id: lesson._id,
              userID: getUserId(),
              startTimestamp: startTimestamp,
              endTimestamp: endTimestamp,
              categoryId: lesson.domain.gutenbergId,
            },
          });
        } else {
          if (lesson.estimatedTime - lesson.confirmedTime > 0) {
            const endTimestampLimited =
              startTimestamp + (lesson.estimatedTime - lesson.confirmedTime);
            saveLessonTime({
              variables: {
                id: lesson._id,
                userID: getUserId(),
                startTimestamp: startTimestamp,
                endTimestamp: endTimestampLimited,
                categoryId: lesson.domain.gutenbergId,
              },
            });
          }
        }
      }
    };
  }, [lesson, saveLessonTime]);

  if (
    !lesson.html &&
    lesson.assetLinks?.length === 1 &&
    lesson.assetLinks[0]?.media?.mimeType === "application/pdf"
  ) {
    return (
      <div>
        <Viewers
          url={`${CONST.OKULUS_URL}/${lesson.assetLinks[0]?.media?.okulusId}`}
        />
      </div>
    );
  } else {
    return (
      <Container maxWidth="md">
        <div className="lesson-content" style={{ fontSize: `${fontSize}px` }}>
          {isVideo ? (
            <YouTubePlayer
              videoId={getYoutubeId(lesson) || ""}
              handleOver={() => console.log("over")}
              handlePlayTimer={handlePlayTimer}
            />
          ) : lesson.html && !validURL(lesson.html) ? (
            <div dangerouslySetInnerHTML={{ __html: lesson.html }} />
          ) : null}
        </div>
      </Container>
    );
  }
};

export default React.memo(Lessons);
